import { connect } from 'react-redux';
import { DATASOURCES } from '../../constants/app';
// import { withRouter } from 'react-router-dom'

import {
  // getDataSource,
  handleToggleNavigationSidebar,
  user_logout as handleLogout,
} from '../../Store/actions';
import { getDataSource, updatePrevLocation } from '../../Store/reducers/app';
// import { DATASOURCES } from '../../constants'
// import { handleLogout } from '../../pages/Login/module'
// import { getNavigationSidebarData } from './module'
// import { handleOnNavClick as ekgReviewHandleOnNavClick } from '../../pages/EkgReview/module'
// import { handleOnNavClick as qtEkgReviewHandleOnNavClick } from '../../pages/QtEkgReview/module'
// import { handleOnNavClick as patientDirectoryHandleOnNavClick } from '../../pages/PatientDirectory/module'
// import { handleOnNavClick as overviewHandleOnNavClick } from '../../pages/Dashboard/module'
// import { handleOnNavClick as reportsHandleOnNavClick } from '../../pages/Reports/module'
// import { handleOnNavClick as settingsHandleOnNavClick } from '../../pages/Settings/module'
import NavigationSidebar from './NavigationSidebar';

const mapActionCreators = {
  handleLogout,
  //   getNavigationSidebarData,
  //   ekgReviewHandleOnNavClick,
  //   qtEkgReviewHandleOnNavClick,
  //   patientDirectoryHandleOnNavClick,
  //   overviewHandleOnNavClick,
  //   reportsHandleOnNavClick,
  //   settingsHandleOnNavClick,
  handleToggleNavigationSidebar,
  updatePrevLocation,
};

const mapStateToProps = (state) => ({
  sidebarIsCollapsed: state.sidebar.sidebarIsCollapsed,
  me: { data: state.user },
  // ekgStackCounts: getDataSource(state, DATASOURCES.EKG_STACK_COUNTS),
});

export default connect(mapStateToProps, mapActionCreators)(NavigationSidebar);
