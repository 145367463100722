import React from 'react';

export const StatusColumn = (props) => {
  const { data, outliner, columnBorderColor } = props;

  const statusBorderStyle = {
    border: `1.5px ${outliner} ${columnBorderColor}`,
    borderRadius: '100px',
    padding: '4px 12px',
    textAlign: 'left',
    width: 'fit-content',
  };

  return <p style={statusBorderStyle}>{data}</p>;
};
