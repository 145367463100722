import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import { styles } from '@material-ui/pickers/views/Calendar/Calendar';

const useStyles = makeStyles({
 root:{
    '& .MuiChip-root': {
        background: 'white',
        // border: '1px solid black',
        fontFamily: 'Atkinson Hyperlegible',
        letterSpacing: '2.5px',
        textTransform: 'uppercase',
        fontSize: '10px',
      },
 }
});

const ChipComponent = ({label, variant, size, color, style}) => {
    const classes=useStyles();
  return (
      <div className={classes.root}>
          <Chip label={label} variant={variant} size={size} color={color} style={{...style}} />
      </div>
  );
};

export default ChipComponent;
