import React from 'react';
import { DateRange } from 'react-date-range';

import Popover from '../components/Popover';
import styles from './DatePopover.module.css';

const DatePopover = (props) => {
  const {
    showMonthArrow = true,
    showMonthAndYearPickers = true,
    handleOnChange,
    handleOnOutsidePopoverClick,
    startDate,
    endDate,
    min,
    max,
  } = props;

  return (
    <Popover className={styles.datePopover} onOutsidePopoverClick={handleOnOutsidePopoverClick}>
      <DateRange
        showMonthArrow={showMonthArrow}
        showMonthAndYearPickers={showMonthAndYearPickers}
        ranges={[
          {
            key: 'selection',
            startDate,
            endDate,
          },
        ]}
        onChange={(dates) => handleOnChange(dates.selection)}
        shownDate={endDate}
        minDate={min}
        maxDate={max}
        moveRangeOnFirstSelection={false}
      />
    </Popover>
  );
};

export default DatePopover;
