import React from 'react';

import SelectDropdown from '../../SelectDropdown';
import Popover from '../components/Popover';
import styles from './DropdownSearchPopover.module.css';
import { colors } from '../../../style/styleSettings';

class DropdownSearchPopover extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchTerm: '',
      value: props.value,
    };
  }

  static getDerivedStateFromProps(props, s) {
    const state = s;

    if (state.value !== props.value) {
      state.value = props.value;
    }

    return state;
  }

  handleOnInputChange = (value) => {
    this.setState({
      searchTerm: value,
    });
  };

  handleOnFocus = () => {
    this.setState({
      value: null,
    });
  };

  handleOnBlur = () => {
    const { value } = this.props;

    this.setState({
      value,
    });
  };

  render() {
    const {
      placeholder,
      isLoading,
      options,
      noResultsMessage,
      getOptionLabel,
      getOptionFilter,
      getOptionValue,
      onChange,
      components,
      handleOnOutsidePopoverClick,
      enableLookAhead = true,
      autoFocus = true,
      label = '',
    } = this.props;
    const { searchTerm, value } = this.state;

    // open by default
    let menuIsOpen;
    if (enableLookAhead) {
      menuIsOpen = searchTerm.length > 0;
    } else {
      menuIsOpen = !value;
    }
    const maxMenuHeight = 200;

    let popoverHeight = 64;

    if (menuIsOpen) {
      popoverHeight += maxMenuHeight + 8;
    }

    return (
      <Popover
        className={styles.dropdownSearchPopover}
        onOutsidePopoverClick={handleOnOutsidePopoverClick}
        // style={{
        //   height: popoverHeight,
        // }}
      >
        <p style={{ textAlign: 'left' }}>Filter</p>
        <SelectDropdown
          titleClassName={styles.label}
          title={label}
          autoFocus={autoFocus}
          isLoading={isLoading}
          isClearable={true}
          backspaceRemovesValue={false}
          placeholder={placeholder}
          inputValue={searchTerm}
          value={value}
          options={options}
          onBlur={this.handleOnBlur}
          onFocus={this.handleOnFocus}
          onChange={onChange}
          onInputChange={this.handleOnInputChange}
          noOptionsMessage={noResultsMessage}
          getOptionLabel={getOptionLabel}
          getOptionFilter={getOptionFilter}
          getOptionValue={getOptionValue}
          components={{
            ...components,
            DropdownIndicator: null,
          }}
          maxMenuHeight={maxMenuHeight}
          menuIsOpen={menuIsOpen}
          customStyles={{
            menu: (styles) => ({ ...styles, position: 'unset', boxShadow: 'unset' }),
            option: (styles, { isDisabled, isSelected }) => ({
              ...styles,
              textAlign: 'left',
              width: 200,
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              fontWeight: 'normal',
              backgroundColor: isDisabled
                ? undefined
                : isSelected
                ? colors.TABLE_GRID_GREY
                : undefined,
              ':hover': {
                ...styles[':hover'],
                backgroundColor: !isDisabled ? colors.TABLE_GRID_GREY : undefined,
              },
            }),
            singleValue: (styles) => ({ ...styles, fontWeight: 'normal' }),
            placeholder: (styles) => ({ ...styles, fontWeight: 'normal' }),
            control: (styles) => ({
              ...styles,
              borderColor: `${colors.ALIVECORE_TEAL} !important`,
              boxShadow: `0px 1px 2px 0px ${colors.LIGHT_GREY1}`,
            }),
          }}
        />
      </Popover>
    );
  }
}

export default DropdownSearchPopover;
