import { SORT_TYPES } from '../../constants/app';

export const DATE_WIDTH = 130;

export const getNextSortDirection = (currentSortDirection) => {
  let nextSortDirection;

  // asc (default) -> desc -> asc -> repeat
  switch (currentSortDirection) {
    case SORT_TYPES.ASC:
      nextSortDirection = SORT_TYPES.DESC;
      break;
    case SORT_TYPES.DESC:
    default:
      nextSortDirection = SORT_TYPES.ASC;
      break;
  }

  return nextSortDirection;
};

export const getSelectedList = (selected) =>
  Object.keys(selected)
    .filter((id) => selected[id] === true)
    .reduce((acc, id) => {
      acc.push(id);
      return acc;
    }, []);
