import React from 'react';
//import classes from './Waveform.scss'
import * as d3 from 'd3';
//import _ from 'lodash'
// XXX import MainsFilter from '../MainsFilter'
import * as Lib from '../Constants/RecordingConstants';

class Waveform extends React.PureComponent {
  render() {
    const { calpulse, height, inverted, mainsFreq, samples, selectedFilter } = this.props;

    //const mult = Number.parseInt((this.props.height / 2) / Lib.PX_PER_MV, 10)

    //const sampleUnitsPerMm = Lib.SAMPLE_UNITS_PER_MV / Lib.GAIN_MM_PER_MV
    //const stripHeightSampleUnits = height / (Lib.PX_PER_MM * sampleUnitsPerMm)
    const stripTimeSec = samples.length / Lib.SAMPLES_PER_SECOND;
    const stripWidthPx = stripTimeSec * Lib.PX_PER_SECOND;
    const mvSwing = Lib.SAMPLE_UNITS_PER_MV * Lib.MV_BANDWIDTH;

    // TODO these may depend on paper speed and gain
    //const calPulseWidthPx = 62.5
    const recordingOffset = calpulse ? 50 : 0;

    let first = true;
    const freqRatio = mainsFreq === 60 ? 5 : 6;
    const prevVals = new Array(freqRatio);
    let index = 0;

    const MainsFilter = (sample) => {
      if (first) {
        let i = 0;
        while (i < freqRatio) {
          prevVals[i] = sample;
          i += 1;
        }
        first = false;
        return sample;
      } else {
        prevVals[index] = sample;
        const sum = prevVals.reduce((s, num) => s + num, 0);
        index += 1;
        if (index === freqRatio) {
          index = 0;
        }
        return sum / freqRatio;
      }
    };

    const xScale = () => d3.scaleLinear().domain([0, samples.length]).range([0, stripWidthPx]);

    const yScale = () => {
      const range = [height, 0];
      if (inverted) {
        range.reverse();
      }
      // TODO this domain needs to be set from properties of the recording
      return d3
        .scaleLinear()
        .domain([mvSwing * -1, mvSwing])
        .range(range);
    };

    const calpulseYscale = () => {
      const range = [height, 0];
      return d3
        .scaleLinear()
        .domain([mvSwing * -1, mvSwing])
        .range(range);
    };

    const renderWaveform = (samplesForWaveform, h) => {
      const returnX = (d, i) => xScale()(i);
      const returnY = (d) => {
        let newD = d;
        if (selectedFilter === 'original') {
          newD = MainsFilter(newD);
        }
        return yScale()(newD);
      };
      const line = d3.line().x(returnX).y(returnY);
      return line(samplesForWaveform);
    };

    // TODO can I just append calpulse to the beginning of the samples now??
    const renderCalpulse = () => {
      const line = d3
        .line()
        .x((d) => xScale()(d[0]))
        .y((d) => calpulseYscale()(d[1]));

      // the first array member is sample index
      // the second is mV
      const data = [
        [30, 0],
        [60, 0],
        [60, Lib.SAMPLE_UNITS_PER_MV],
        [120, Lib.SAMPLE_UNITS_PER_MV],
        [120, 0],
        [150, 0],
      ];

      return line(data);
    };

    return (
      // <g className={classes.tracing}>
      <g
        style={{
          fill: 'none',
          stroke: '#3D2525',
          strokeWidth: '1.5px',
          strokeLinejoin: 'round',
        }}
      >
        {calpulse && <path d={renderCalpulse()} />}
        <g transform={`translate(${recordingOffset},0)`}>
          <path d={renderWaveform(this.props.samples, this.props.height)} />
        </g>
      </g>
    );
  }
}

export default Waveform;
