import styled from 'styled-components';
//import { colors } from '../../../style/styleSettings';

const BottomLineTitle = styled.span`
  width: 56px;
  height: 7.92px;
  border-radius: 2px;
  background-color: #2d9f86;
  display: block;
  margin-top: 8px;
`;

export default BottomLineTitle;
