import { connect } from 'react-redux'
import RequestNewPassword from './RequestNewPassword'
import {
  postRequestNewPassword,
  handleChange,
  handleKeyDown,
} from './module'

const mapActionCreators = {
  handleSubmit: postRequestNewPassword,
  handleChange,
  handleKeyDown,
}

const mapStateToProps = state => ({
  requestNewPassword: state.requestNewPassword,
})

export default connect(
  mapStateToProps,
  mapActionCreators
)(RequestNewPassword)
