// --------------------------------------------
// Constants to be used in actions and reducers
// ---------------------------------------------
// constants for kardiaRx IDTF ECG Confirm
export const TEAM_SETTINGS_FETCH_REQUEST = `TEAM_SETTINGS_FETCH_REQUEST`;
export const TEAM_SETTINGS_FETCH_SUCCESS = `TEAM_SETTINGS_FETCH_SUCCESS`;
export const TEAM_SETTINGS_FETCH_FAILURE = `TEAM_SETTINGS_FETCH_FAILURE`;

export const TEAM_SETTINGS_CHANGES_REQUEST = `TEAM_SETTINGS_CHANGES_REQUEST`;
export const TEAM_SETTINGS_CHANGES_SUCCESS = `TEAM_SETTINGS_CHANGES_SUCCESS`;
export const TEAM_SETTINGS_CHANGES_FAILURE = `TEAM_SETTINGS_CHANGES_FAILURE`;

export const BUSINESS_HOURS_FETCH_REQUEST = `BUSINESS_HOURS_FETCH_REQUEST`;
export const BUSINESS_HOURS_FETCH_SUCCESS = `BUSINESS_HOURS_FETCH_SUCCESS`;
export const BUSINESS_HOURS_FETCH_FAILURE = `BUSINESS_HOURS_FETCH_FAILURE`;

export const BUSINESS_HOURS_CHANGES_REQUEST = `BUSINESS_HOURS_CHANGES_REQUEST`;
export const BUSINESS_HOURS_CHANGES_SUCCESS = `BUSINESS_HOURS_CHANGES_SUCCESS`;
export const BUSINESS_HOURS_CHANGES_FAILURE = `BUSINESS_HOURS_CHANGES_FAILURE`;
