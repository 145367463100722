import React from 'react';

import UncheckedCheckboxIcon from '../../../../Assets/images/checkbox_empty.svg';
import CheckedCheckboxIcon from '../../../../Assets/images/checkboxChecked.svg';
import styles from '../../Forms.module.css';

class CheckboxInput extends React.Component {
  state = {
    selected: this.props.input.value,
    action: null, // what updated the checkbox
  };

  componentDidMount() {
    const { selected, action } = this.state;
    const { input } = this.props;
    // only update it if user has not interacted with the checkbox.
    if (input.value !== selected && action === null) {
      this.updateSelected(input.value);
    }
  }

  componentDidUpdate(prevProps) {
    const { selected, action } = this.state;
    const { input } = this.props;
    // only update it if user has not interacted with the checkbox.
    if (input.value !== selected && action === null) {
      this.updateSelected(input.value);
    }
  }

  updateSelected(updatedValue) {
    this.setState({
      selected: updatedValue,
    });
  }

  handleOnClick = (e) => {
    const { input, onClick, disabled, onCheckBoxChange } = this.props;

    const { selected } = this.state;

    if (!disabled) {
      const newSelected = !selected;
      this.setState({
        selected: newSelected,
        action: 'click',
      });

      if (typeof onClick === 'function') {
        onClick();
      }

      input.onChange(newSelected);

      if (typeof onCheckBoxChange === 'function') {
        onCheckBoxChange(newSelected);
      }
    }
  };

  render() {
    const { input, label, getLabel, className, disabled, labelClassName } = this.props;
    const { selected } = this.state;

    const inputComponent =
      typeof getLabel === 'function' ? (
        getLabel()
      ) : (
        <span className={labelClassName || styles.checkboxLabel}>{label}</span>
      );

    const cn = className || styles.checkboxGroup;

    return (
      <div id={input.name} name={input.name} onClick={this.handleOnClick}>
        <label className={cn} htmlFor={input.name}>
          {!disabled ? (
            <div>
              {selected ? (
                <CheckedCheckboxIcon className={styles.checkboxIcon} />
              ) : (
                <UncheckedCheckboxIcon className={styles.checkboxIcon} />
              )}
            </div>
          ) : (
            <div>
              {selected ? (
                <CheckedCheckboxIcon className={styles.disabledCheckboxIcon} />
              ) : (
                <UncheckedCheckboxIcon className={styles.disabledCheckboxIcon} />
              )}
            </div>
          )}
          {inputComponent}
        </label>
      </div>
    );
  }
}

export default CheckboxInput;
