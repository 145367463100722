import React from 'react';
import moment from 'moment';
import classNames from 'classnames';

import styles from './PageUpdated.module.css';
import RefreshIcon from '../../Assets/images/icons/refresh.svg';
import { getTimeFormat, getRegion } from '../../constants/app';

export const PageUpdated = (props) => {
  const { isLoading, handleOnClick, lastUpdated, hasTabs, className, me } = props;

  const getTimeFormatPlaceholder = () =>
    getRegion() === 'us' ? '\u2013\u2013:\u2013\u2013 \u2013\u2013' : '\u2013\u2013:\u2013\u2013';
  const pageUpdatedClasses = classNames({
    [styles.pageUpdatedHigh]: !hasTabs,
    [styles.pageUpdatedLow]: hasTabs,
    [className]: className !== undefined,
  });
  return (
    <div className={pageUpdatedClasses}>
      <p>Last Refreshed</p>
      <div className={styles.updatedTime}>
        <span>
          {lastUpdated ? moment(lastUpdated).format(getTimeFormat(me)) : getTimeFormatPlaceholder()}
        </span>
      </div>
      <button
        className={styles.refreshPage}
        onClick={!isLoading ? handleOnClick : undefined}
        disabled={isLoading}
      >
        {!isLoading ? (
          <RefreshIcon />
        ) : (
          <div className={styles.spinningRefresh}>
            <RefreshIcon />
          </div>
        )}
      </button>
    </div>
  );
};

export default PageUpdated;
