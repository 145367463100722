export const headCells = [
  {
    id: 'date',
    label: 'Date',
  },
  {
    id: 'timeOfApp',
    label: 'Time',
  },
  {
    id: 'coach',
    label: 'Coach',
  },
  {
    id: 'member',
    label: 'Member',
  },
  {
    id: 'status',
    label: 'Status',
  },
  {
    id: 'duration',
    label: 'Duration',
  },
  {
    id: 'type',
    label: 'Type',
  },
  {
    id: 'method',
    label: 'Method',
  },
  {
    id: 'actions',
    label: 'Actions',
  },
];

export const memberFilters = {
  status: [
    {
      id: 'status1',
      name: 'Scheduled',
      type: 'status',
      code: 1,
    },
    {
      id: 'status2',
      name: 'Completed',
      type: 'status',
      code: 4,
    },
    {
      id: 'status3',
      name: 'Member Missed',
      type: 'status',
      code: 8,
    },
    {
      id: 'status4',
      name: 'Coach Missed',
      type: 'status',
      code: 10,
    },
    {
      id: 'status5',
      name: 'Cancelled',
      type: 'status',
      code: 5,
    },
    {
      id: 'status6',
      name: 'In Progress',
      type: 'status',
      code: 3,
    },
  ],
};
