import { Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { ROUTES } from '../../constants/app';
import withStartConsultation from '../Dashboard/withStartConsultation';

const ToastButton = ({
  checkStatus,
  patientDetails,
  patientDetailsLoader,
  id,
  alertHandler,
  buttonProps,
  reviewSession,
  memberInWaitingRoom,
  alertCloseFlag,
  hideToast
}) => {
  const [clicked, setClicked] = useState(false);
  console.log(patientDetails, patientDetailsLoader, id);
  useEffect(() => {
    if (clicked && patientDetails && patientDetails.appointmentId && id && !patientDetailsLoader) {
      alertHandler();
      setClicked(false);
    }
  }, [clicked, patientDetails, patientDetailsLoader, id]);

  useEffect(() => {
    if(alertCloseFlag){
      console.log('in toast alert... ')
      hideToast()
    }
  }, [alertCloseFlag])

  const history = useHistory();
  const toastButtonClick = () => {
    if(memberInWaitingRoom){
      setClicked(true)
    }else if(reviewSession){
      history.push(`${ROUTES.MEMBER_PROFILE}/${patientDetails?.patient?.patientId}`);
    }
  }
  return (
    <Button
      onClick={toastButtonClick}
      style={{ marginLeft: '20px', color: '#fff', fontWeight: 'bold' }}
      {...buttonProps}
    >
      {memberInWaitingRoom ? 'JOIN CALL' : reviewSession ? 'REVIEW PROFILE' : 'REJOIN'}
    </Button>
  );
};

export default withStartConsultation(ToastButton);
