import * as actionTypes from '../actions/actionTypes';

const initialState = {
  loading: false,
  data: {
    appointments: [],
    pages: null,
  },
  error: null,
  sessionsFilterParams: null,
  sessionsSortParams:null,
};

export function adminSessionsReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_ADMIN_SESSIONS_LOADING:
      return {
        ...state,
        loading: true,
        data: [],
      };
    case actionTypes.GET_ADMIN_SESSIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          appointments: action.payload.data,
          pages: action.payload.pages,
        },
      };
    case actionTypes.GET_ADMIN_SESSIONS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
      case actionTypes.SET_SESSIONS_FILTER_DATA:
        return {
          ...state,
          sessionsFilterParams: action.payload,
        };
        case actionTypes.SET_SESSIONS_SORT_DATA:
          return {
            ...state,
            sessionsSortParams: action.payload,
          };

    default:
      return state;
  }
}
