import React from 'react';

import Popover from '../components/Popover';
import styles from './ListPopover.module.css';

const ListPopover = (props) => {
  const {
    list,
    selected,
    handleOnClick,
    handleOnOutsidePopoverClick,
    getOptionLabel,
    getOptionValue,
  } = props;

  let listContent = null;

  if (list) {
    listContent = (
      <ul className={styles.list}>
        {list.map((el) => {
          const id = getOptionValue(el);
          const active = selected === id;
          const cn = active ? styles.activeRow : styles.row;

          return (
            <button key={id} className={cn} onClick={() => handleOnClick(id)}>
              {active && <div className={styles.bullet} />}
              {getOptionLabel(el)}
            </button>
          );
        })}
      </ul>
    );
  }

  return (
    <Popover className={styles.listPopover} onOutsidePopoverClick={handleOnOutsidePopoverClick}>
      {listContent}
    </Popover>
  );
};

export default ListPopover;
