/*eslint-disable */

import { from as fromPromise, Observable } from 'rxjs';
import buildQuery from 'odata-query';
import microServiceConfig from './microserviceConfig';
import axios from 'axios';
import { getToken } from './../Auth/AuthUtilities';
//import get from "lodash.get";

/**
 * A function that intercepts the response from API to detect token expiration and refresh them
 *
 * @param {any} param some kind of data needs to be passed to the request body
 *
 * @returns {Object} an object of data that will be used inside the request body
 */

/**
 * A function that constructs the headers of the HTTP request
 *
 * @param {string} microservice a microservice endpoint
 * @returns {headers} an object of data that will be used inside the headers
 */

function constructHeader(props) {
  let headers = {
    Authorization: 'Bearer ' + getToken(),
    'Content-Type': 'application/json',
  };
  return headers;
}

/**
 * A function that constructs the url to be called for the microservice
 *
 * @param {string} microservice a microservice endpoint
 * @param {string} queryString string for url
 * @param {Array<urlParam>} customUrlParams replacement key/value for service url
 * @returns {string} the url to be called
 */

function constructUrl(
  microservice: string,
  queryString?: ?string,
  customUrlParams?: Array<urlParam>,
): string {
  let url = `${process.env.REACT_APP_TELEKARDIA_API_BASE}${microServiceConfig[microservice].url}/`;
  if (customUrlParams) {
    customUrlParams.forEach((element) => {
      url = url.replace(`{${element.key}}`, element.value);
    });
  }
  if (queryString) {
    url = `${url}?${encodeURI(queryString)}`;
  }
  return url;
}

/**
 * A function that makes a GET HTTP call to find some kind of entity in the backend
 *
 * @param {string} microservice a microservice endpoint
 * @param {string} queryString string for url
 * @param {Array<urlParam>} customUrlParams replacement key/value for service url
 * @returns {Observable<*>}
 */
function find$(
  microservice: string,
  queryString?: string,
  customUrlParams?: Array<urlParam>,
): Observable<*> {
  // construct the necessary headers
  const url = constructUrl(microservice, queryString, customUrlParams);
  let headers = constructHeader(microservice);
  // make the axios call and supply in the necesary info
  return fromPromise(
    axios
      .get(url, {
        headers,
      })
      .then((res) => res.data),
  );
}

/**
 * A function that makes a POST HTTP call to persist the required entity to the backend
 * @param {string} microservice a microservice endpoint
 * @param {any} body the required entity that needs to be persisted
 * @returns {Observable<*>}
 */
function save$(
  microservice: string,
  body: any,
  customUrlParams: Array<urlParam>,
  queryString?: string,
): Observable<*> {
  // construct the neccessary headers
  let headers = constructHeader(microservice);
  let method = '';
  let url = `${process.env.REACT_APP_TELEKARDIA_API_BASE}${microServiceConfig[microservice].url}/`;
  if (customUrlParams) {
    customUrlParams.forEach((element) => {
      url = url.replace(`{${element.key}}`, element.value);
    });
  }

  if (body && body.id) {
    method = 'PUT';
    url = `${url}${body.id}`;
  } else {
    method = 'POST';
  }

  if (queryString) {
    url = `${url}?${encodeURI(queryString)}`;
  }
  // make the axios call and supply in the necessary info
  return fromPromise(
    axios({
      method,
      url,
      data: body,
      headers,
    }),
  );
}

function add$(
  microservice: string,
  body: any,
  customUrlParams: Array<urlParam>,
  queryString?: string,
): Observable<*> {
  // construct the neccessary headers

  let headers = constructHeader(microservice);

  let method = 'POST';
  let url = `${process.env.REACT_APP_TELEKARDIA_API_BASE}${microServiceConfig[microservice].url}`;
  // make the axios call and supply in the necessary info
  return fromPromise(
    axios({
      method,
      url,
      data: body,
      headers,
    }),
  );
}

function get$(
  microservice: string,
  body: any,
  customUrlParams: Array<urlParam>,
  queryString?: string,
): Observable<*> {
  let headers = constructHeader(microservice);
  if (body && body.authorizationToken) {
    headers['Authorization'] = 'Bearer ' + body.authorizationToken;
    delete body.authorizationToken;
  }

  let baseUrl = `${body.baseUrl}`;

  let method = 'GET';
  let url = `${process.env.REACT_APP_TELEKARDIA_API_BASE}${baseUrl}`;
  return fromPromise(
    axios({
      method,
      url,
      data: body,
      headers,
    }),
  );
}

function post$(microservice: string, body: any): Observable<*> {
  let headers = constructHeader(microservice);
  if (body && body.authorizationToken) {
    headers['Authorization'] = 'Bearer ' + body.authorizationToken;
    delete body.authorizationToken;
  }

  let baseUrl = `${body.baseUrl}`;

  let method = 'POST';
  let url = `${process.env.REACT_APP_TELEKARDIA_API_BASE}${baseUrl}`;
  return fromPromise(
    axios({
      method,
      url,
      data: body,
      headers,
    }),
  );
}

function put$(
  microservice: string,
  body: any,
  customUrlParams: Array<urlParam>,
  queryString?: string,
): Observable<*> {
  // construct the neccessary headers

  let headers = constructHeader(microservice);
  let method = 'PUT';
  let url = `${process.env.REACT_APP_TELEKARDIA_API_BASE}${microServiceConfig[microservice].url}`;
  // make the axios call and supply in the necessary info
  return fromPromise(
    axios({
      method,
      url,
      data: body,
      headers,
    }),
  );
}

/**
 * A function that makes a DELETE HTTP call to delete a specific entity from the backend
 *
 * @param {string} microservice a microservice endpoint
 * @param {string} id the id of the entity the frontend service wishes to delete
 * @param {Array<urlParam>} customUrlParams replacement key/value for service url
 * @returns {Observable<*>}
 */

function delete$(
  microservice: string,
  id: string,
  customUrlParams: Array<urlParam>,
): Observable<*> {
  // construct the necessary headers
  let headers = constructHeader(microservice);

  // make the axios call and supply in the necessary info

  let url = `${process.env.REACT_APP_TELEKARDIA_API_BASE}${microServiceConfig[microservice].url}/${id}`;
  if (customUrlParams) {
    customUrlParams.forEach((element) => {
      url = url.replace(`{${element.key}}`, element.value);
    });
  }

  return fromPromise(
    axios
      .delete(url, {
        headers,
      })
      .then((res) => res.data),
  );
}

function wrapper(microservice: string) {
  return {
    find$: (...args: any) => {
      return find$(microservice, ...args);
    },
    save$: (...args: any) => {
      return save$(microservice, ...args);
    },
    add$: (...args: any) => {
      return add$(microservice, ...args);
    },

    post$: (...args: any) => {
      return post$(microservice, ...args);
    },
    get$: (...args: any) => {
      return get$(microservice, ...args);
    },
  };
}

export function formQuery(filter: any) {
  let query: string = buildQuery({ filter }).replace('?$filter=', 'queryValue={"filter":"');

  query = query.replace("'", '');
  query = query.replace("'", '"}');
  return query;
}

const api = {};
Object.keys(microServiceConfig).map((value: string) => (api[value] = wrapper(value)));

export { api };
