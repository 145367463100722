import React from 'react';
import Calendar from './Calendar/calendar';
import './Appointments.scss';
import '../../App.scss';
import { Box } from '@material-ui/core';
import Alert from '../Shared/Alert/Alerts';
import Successalert from '../Shared/Alert/AvailabilityAlert';

const Appointments = (props) => {
  const [alert, setalert] = React.useState();

  const reassignsubmit = (prps) => {
    setalert(
      <Successalert
        component="clinicianCalendar"
        title={`The session has been successfully assigned to ${prps.doctorFirstName} ${prps.doctorLastName}`}
      />,
    );
  };
  return (
    <div>
      <div className="right_content">
        <div className="main_content right_container">
          <Box pt={2} pl={2} pr={2}>
            {alert}
          </Box>
          <Calendar
            {...props}
            reassignsubmit={reassignsubmit}
            error={(message) => setalert(<Alert date={new Date()} message={message} />)}
          />
        </div>
      </div>
    </div>
  );
};

export default Appointments;
