import React from 'react';

class Popover extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }
  componentDidMount() {
    document.body.addEventListener('mousedown', this.handleBodyClick, false);
  }
  componentWillUnmount() {
    document.body.removeEventListener('mousedown', this.handleBodyClick, false);
  }

  handleBodyClick = (e) => {
    const { onOutsidePopoverClick } = this.props;

    if (typeof onOutsidePopoverClick === 'function' && !this.ref.contains(e.target)) {
      onOutsidePopoverClick();
      // e.stopPropagation()
    }
  };

  render() {
    const { className, style, children } = this.props;
    return (
      <div
        className={className}
        ref={(node) => {
          this.ref = node;
        }}
        style={style}
      >
        {children}
      </div>
    );
  }
}

export default Popover;
