import { connect } from 'react-redux'
import ResetPassword from './ResetPassword'
import {
  handleChange,
  handleKeyDown,
  submitNewPassword,
  getToken,
} from './module'

const mapActionCreators = {
  handleChange,
  handleKeyDown,
  submitNewPassword,
  getToken,
}

const mapStateToProps = state => ({
  resetPassword: state.resetPassword,
})

export default connect(
  mapStateToProps,
  mapActionCreators
)(ResetPassword)
