export const HEART_HABIT_FIELDS = {
  FOCUS: 'focus',
  FOCUS_NOTE: 'focusNote',
  REC_PATTERN: 'seriespattern',
  START_DATE: 'startDate',
  END_DATE: 'endDate',
};

export const DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
export const DAYS_MAP = {
  Sunday: '0',
  Monday: '1',
  Tuesday: '2',
  Wednesday: '3',
  Thursday: '4',
  Friday: '5',
  Saturday: '6',
};
