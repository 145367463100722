import styled from 'styled-components';
import { colors } from '../../../style/styleSettings';

export const Row = styled.li`
  list-style-type: none;
  padding: 16px;
  background-color: ${colors.WHITE};
  border-bottom: 1px solid ${colors.LIGHT_GREY};

  &:last-child {
    border-bottom: none;
  }
`;

export const DragHandle = styled.div`
  width: 30px;
  left: 0;
  top: 50%;
  cursor: grabbing;
`;

export const Table = styled.ul`
  list-style-type: none;
  padding-left: 0;
`;

export const Note = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
`;
