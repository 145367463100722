import { colors } from '../style/styleSettings';

const theme = {
  primaryColor: colors.ALIVECORE_TEAL,
  iconColor: colors.ALIVECORE_TEAL,
  // primaryColor: colors.PRIMARY_CTA,
  //   iconColor: colors.PRIMARY_CTA,
  // primaryColor: colors.YELLOW,
  // iconColor: colors.YELLOW,
  secondaryTextColor: colors.PRIMARY_DARK_TEXT,
  fontColor: colors.PRIMARY_DARK_TEXT,
};

export default theme;
