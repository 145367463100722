import React from 'react'
import classes from './NoEKGPreview.css'

export const NoEKGPreview = props => {
  const {
    height,
  } = props

  return (
    <div
      className={classes.noEKGPreview}
      style={{ height,display:'flex', justifyContent:'center', alignItems:'center',marginTop:'3px' }}
    >
      <div className={classes.text}>
        ECG Preview not available
      </div>
    </div>
  )
}

export default NoEKGPreview
