export const IMPLANTED_DEVICES = [
  {
    id: 'pacemaker',
    name: 'PaceMaker',
  },
  {
    id: 'icd',
    name: 'ICD',
  },
  {
    id: 'no',
    name: 'No',
  },
  {
    id: 'unknown',
    name: 'Unknown',
  },
];

// country list
export const countryList = [
  {
    name: 'United States',
    value: 'US',
  },
];
// Region list
export const RegionList = {
  AL: { name: 'ALABAMA', value: 'AL' },
  AK: { name: 'ALASKA', value: 'AK' },
  AS: { name: 'AMERICAN SAMOA', value: 'AS' },
  AZ: { name: 'ARIZONA', value: 'AZ' },
  AR: { name: 'ARKANSAS', value: 'AR' },
  CA: { name: 'CALIFORNIA', value: 'CA' },
  CO: { name: 'COLORADO', value: 'CO' },
  CT: { name: 'CONNECTICUT', value: 'CT' },
  DE: { name: 'DELAWARE', value: 'DE' },
  DC: { name: 'DISTRICT OF COLUMBIA', value: 'DC' },
  FM: { name: 'FEDERATED STATES OF MICRONESIA', value: 'FM' },
  FL: { name: 'FLORIDA', value: 'FL' },
  GA: { name: 'GEORGIA', value: 'GA' },
  GU: { name: 'GUAM', value: 'GU' },
  HI: { name: 'HAWAII', value: 'HI' },
  ID: { name: 'IDAHO', value: 'ID' },
  IL: { name: 'ILLINOIS', value: 'IL' },
  IN: { name: 'INDIANA', value: 'IN' },
  IA: { name: 'IOWA', value: 'IA' },
  KS: { name: 'KANSAS', value: 'KS' },
  KY: { name: 'KENTUCKY', value: 'KY' },
  LA: { name: 'LOUISIANA', value: 'LA' },
  ME: { name: 'MAINE', value: 'ME' },
  MH: { name: 'MARSHALL ISLANDS', value: 'MH' },
  MD: { name: 'MARYLAND', value: 'MD' },
  MA: { name: 'MASSACHUSETTS', value: 'MA' },
  MI: { name: 'MICHIGAN', value: 'MI' },
  MN: { name: 'MINNESOTA', value: 'MN' },
  MS: { name: 'MISSISSIPPI', value: 'MS' },
  MO: { name: 'MISSOURI', value: 'MO' },
  MT: { name: 'MONTANA', value: 'MT' },
  NE: { name: 'NEBRASKA', value: 'NE' },
  NV: { name: 'NEVADA', value: 'NV' },
  NH: { name: 'NEW HAMPSHIRE', value: 'NH' },
  NJ: { name: 'NEW JERSEY', value: 'NJ' },
  NM: { name: 'NEW MEXICO', value: 'NM' },
  NY: { name: 'NEW YORK', value: 'NY' },
  NC: { name: 'NORTH CAROLINA', value: 'NC' },
  ND: { name: 'NORTH DAKOTA', value: 'ND' },
  MP: { name: 'NORTHERN MARIANA ISLANDS', value: 'MP' },
  OH: { name: 'OHIO', value: 'OH' },
  OK: { name: 'OKLAHOMA', value: 'OK' },
  OR: { name: 'OREGON', value: 'OR' },
  PW: { name: 'PALAU', value: 'PW' },
  PA: { name: 'PENNSYLVANIA', value: 'PA' },
  PR: { name: 'PUERTO RICO', value: 'PR' },
  RI: { name: 'RHODE ISLAND', value: 'RI' },
  SC: { name: 'SOUTH CAROLINA', value: 'SC' },
  SD: { name: 'SOUTH DAKOTA', value: 'SD' },
  TN: { name: 'TENNESSEE', value: 'TN' },
  TX: { name: 'TEXAS', value: 'TX' },
  UT: { name: 'UTAH', value: 'UT' },
  VT: { name: 'VERMONT', value: 'VT' },
  VI: { name: 'VIRGIN ISLANDS', value: 'VI' },
  VA: { name: 'VIRGINIA', value: 'VA' },
  WA: { name: 'WASHINGTON', value: 'WA' },
  WV: { name: 'WEST VIRGINIA', value: 'WV' },
  WI: { name: 'WISCONSIN', value: 'WI' },
  WY: { name: 'WYOMING', value: 'WY' },
};
