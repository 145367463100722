import React from 'react';
import MaskedInputComponent from 'react-text-mask';
import styles from '../../Forms.module.css';
import { IconButton } from '@material-ui/core';

const MaskedInput = (props) => {
  const {
    input,
    meta: { error, warning },
    label,
    getLabel,
    required,
    autoFocus,
    mask,
    placeholder,
    disabled,
    inline,
    type = 'text',
    rightIcon: RightIcon,
    onRightIconClick,
  } = props;

  const inputComponent =
    typeof getLabel === 'function' ? (
      getLabel()
    ) : (
      <span className={styles.label} style={required ? {} : { paddingLeft: 0 }}>
        {label}
      </span>
    );

  const cn = inline ? styles.inputRowGroup : styles.inputGroup;

  return (
    <label className={cn} htmlFor={input.name}>
      {required && <span className={styles.required}>*</span>}
      {inputComponent}
      <div style={{ position: 'relative' }}>
        <MaskedInputComponent
          {...input}
          autoComplete="off"
          className={styles.input}
          type={type}
          disabled={disabled}
          required={required}
          id={input.name}
          mask={mask}
          autoFocus={autoFocus}
          placeholder={placeholder}
          style={{ width: '100%', ...(!!RightIcon ? { paddingRight: 30 } : {}) }}
        />
        {!!RightIcon && (
          <IconButton
            onClick={() => {
              onRightIconClick && onRightIconClick();
            }}
            className={styles.rightIconBtn}
          >
            <RightIcon />
          </IconButton>
        )}
      </div>
      {warning && <span className={styles.error}>{warning}</span>}
      {error && <span className={styles.error}>{error}</span>}
    </label>
  );
};

export default MaskedInput;
