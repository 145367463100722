import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import EmailIcon from '@material-ui/icons/Email';
import { IconButton } from '@material-ui/core';
import styles from './SearchInput.module.css';

const SearchInput = (props) => {
  const { handleOnChange, placeholder = 'Search...', value, autoFocus, onKeyUp,icon } = props;

  return (
    <div style={{ position: 'relative' }}>
      <input
        onKeyUp={onKeyUp}
        autoFocus={autoFocus}
        onChange={(e) => handleOnChange(e.target.value)}
        className={styles.searchInput}
        placeholder={placeholder}
        value={value}
        style={{ width: '100%', paddingRight: 30 }}
      />
     {icon &&  <IconButton onClick={() => handleOnChange('')} className={styles.closeBtn}>
       {icon}
      </IconButton>}
      {!!value && (
        <IconButton onClick={() => handleOnChange('')} className={styles.closeBtn}>
          <CloseIcon />
        </IconButton>
      )}
    </div>
  );
};

export default SearchInput;
