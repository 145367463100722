import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../Login/login.module.css';
import Loading from '../../Components/Loading';
// import KardiaProLogo from 'static/images/kardia_pro_logo.svg'
import CheckmarkIcon from '../../Assets/images/icons/check-circle.svg';
import ValidationRules from '../../Components/ValidationRules';
import Logo from '../Login/Logo';

class ResetPassword extends React.Component {
  UNSAFE_componentWillMount() {
    const { match, location } = this.props;
    this.props.getToken(match, location);
  }
  render() {
    const {
      handleChange,
      handleKeyDown,
      submitNewPassword,
      resetPassword: { error, errorMsg, fetching, success, validationTests },
    } = this.props;

    return (
      <div data-page="reset-password" className={styles.resetPassword}>
        <div className={styles.loginOuterCont}>
          <div className={styles.loginBox}>
            <section className={styles.header}>
              <div className={styles.logo}>
                <Logo />
              </div>
            </section>
            <section className={styles.content}>
              <div className={styles.contentInner}>
                {success ? (
                  <div className={styles.preambleSuccess}>
                    <CheckmarkIcon />
                    <h4>You have successfully reset your password</h4>
                    <Link to="/login" className={styles.signInLink}>
                      Go To Login
                    </Link>
                  </div>
                ) : (
                  <div>
                    <div className={styles.preamble}>
                      <p>Reset your password</p>
                    </div>
                    <div className={styles.inputField}>
                      <input
                        className={styles.defaultTextInput}
                        name="password"
                        type="password"
                        onKeyDown={handleKeyDown}
                        onChange={handleChange}
                        placeholder="New password"
                      />
                    </div>
                    <div className={styles.inputField}>
                      <input
                        className={styles.defaultTextInput}
                        name="passwordConfirmation"
                        type="password"
                        onKeyDown={handleKeyDown}
                        onChange={handleChange}
                        placeholder="Confirm your new password"
                      />
                    </div>
                    {error && <h2 className={styles.errorMsg}>{errorMsg}</h2>}

                    <div className={styles.validationWrapper}>
                      <ValidationRules validationTests={validationTests} />
                    </div>
                    <div>
                      {fetching ? (
                        <button className={styles.disabledButton}>
                          <div className={styles.loginLoading}>
                            <Loading />
                          </div>
                        </button>
                      ) : (
                        <button className={styles.signUpButton} onClick={submitNewPassword}>
                          Reset Password
                        </button>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

export default ResetPassword;
