// eslint-disable-next-line
// https://github.com/alivecor/AliveECG-iOS/blob/3f156026e8f9f17fe71ce1ef7a4ef9d8ad0e3f1b/AliveECG/Classes/ACAlgorithmResultTheme.m#L25
export const EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES = {
  NO_ANALYSIS: 'NO_ANALYSIS',
  UNCLASSIFIED: 'UNCLASSIFIED',
  TOO_SHORT: 'TOO_SHORT',
  TOO_LONG: 'TOO_LONG',
  NORMAL: 'NORMAL',
  TACHYCARDIA: 'TACHYCARDIA',
  BRADYCARDIA: 'BRADYCARDIA',
  AFIB: 'AFIB',
  UNREADABLE: 'UNREADABLE',
  'SINUS_RHYTHM,MULTIPLE_PACS': 'SINUS_RHYTHM,MULTIPLE_PACS',
  'SINUS_RHYTHM,WIDE_QRS': 'SINUS_RHYTHM,WIDE_QRS',
  'SINUS_RHYTHM,MULTIPLE_PVCS': 'SINUS_RHYTHM,MULTIPLE_PVCS',
  SINUS_RHYTHM: 'SINUS_RHYTHM',
  NORMAL_SINUS_RHYTHM: 'NORMAL_SINUS_RHYTHM',
};

export const EKG_CLASSNAMES = {
  ekgOther: 'ekgOther',
  ekgNormal: 'ekgNormal',
  ekgAbnormal: 'ekgAbnormal',
  ekgNoAnalysis: 'ekgNoAnalysis',
};

// matches stack recording payload key field
export const EKG_STACK_SORT = {
  RECORDED_DATE_ASC: 'RECORDED_DATE_ASC',
  RECORDED_DATE_DESC: 'RECORDED_DATE_DESC',
  LAST_NAME_ASC: 'LAST_NAME_ASC',
  LAST_NAME_DESC: 'LAST_NAME_DESC',
};

export const EKG_STACK_FILTERS = {
  ALL_EKGS: null,
  ABNORMAL: 'ABNORMAL',
  NORMAL: 'NORMAL',
  NO_ANALYSIS: 'NO_ANALYSIS',
  OTHER: 'OTHER',
};

export const EKG_INTERPRETATION_SEVERITY_TYPES = {
  HIGH: 'HIGH',
  MEDIUM: 'MEDIUM',
  LOW: 'LOW',
  [null]: null,
};

export const getEKGLegendOrder = () => [
  EKG_CLASSNAMES.ekgNormal,
  EKG_CLASSNAMES.ekgAbnormal,
  EKG_CLASSNAMES.ekgOther,
  EKG_CLASSNAMES.ekgNoAnalysis,
];

export const getEKGStackSortOrder = () => [
  EKG_STACK_SORT.RECORDED_DATE_ASC,
  EKG_STACK_SORT.LAST_NAME_ASC,
  EKG_STACK_SORT.RECORDED_DATE_DESC,
  EKG_STACK_SORT.LAST_NAME_DESC,
];

export const getEKGStackFilterOrder = () => [
  EKG_STACK_FILTERS.ALL_EKGS,
  EKG_STACK_FILTERS.NORMAL,
  EKG_STACK_FILTERS.ABNORMAL,
  EKG_STACK_FILTERS.OTHER,
  EKG_STACK_FILTERS.NO_ANALYSIS,
];

export const getEKGLegendDisplayText = (ekgClassName) => {
  let displayText;

  switch (ekgClassName) {
    case EKG_CLASSNAMES.ekgNormal:
      displayText = 'Normal';
      break;
    case EKG_CLASSNAMES.ekgAbnormal:
      displayText = 'Abnormal';
      break;
    case EKG_CLASSNAMES.ekgOther:
      displayText = 'Other';
      break;
    case EKG_CLASSNAMES.ekgNoAnalysis:
      displayText = 'No Analysis';
      break;
    default:
      break;
  }
  return displayText;
};

export const getEKGStackSortDisplayText = (ekgStackSort) => {
  let displayText;

  switch (ekgStackSort) {
    case EKG_STACK_SORT.RECORDED_DATE_ASC:
      displayText = '(ASC) Recorded Date';
      break;
    case EKG_STACK_SORT.RECORDED_DATE_DESC:
      displayText = '(DESC) Recorded Date';
      break;
    case EKG_STACK_SORT.LAST_NAME_ASC:
      displayText = '(ASC) Last Name';
      break;
    case EKG_STACK_SORT.LAST_NAME_DESC:
      displayText = '(DESC) Last Name';
      break;
    default:
      break;
  }
  return displayText;
};

export const getEkgResultOptions = () =>
  getEKGStackFilterOrder().reduce((acc, ekgStackFilter) => {
    acc.push({
      id: ekgStackFilter,
      label: getEKGStackFilterDisplayText(ekgStackFilter),
    });
    return acc;
  }, []);

export const getEKGStackFilterDisplayText = (ekgStackFilter) => {
  let displayText;

  switch (ekgStackFilter) {
    case EKG_STACK_FILTERS.ALL_EKGS:
      displayText = 'All ECGs';
      break;
    case EKG_STACK_FILTERS.ABNORMAL:
      displayText = 'Abnormal';
      break;
    case EKG_STACK_FILTERS.NORMAL:
      displayText = 'Normal';
      break;
    case EKG_STACK_FILTERS.OTHER:
      displayText = 'Other';
      break;
    case EKG_STACK_FILTERS.NO_ANALYSIS:
      displayText = 'No Analysis';
      break;
    default:
      break;
  }
  return displayText;
};

export const getHighestEKGInterpretationSeverityTypesOrder = () => [
  EKG_INTERPRETATION_SEVERITY_TYPES.HIGH,
  EKG_INTERPRETATION_SEVERITY_TYPES.MEDIUM,
  EKG_INTERPRETATION_SEVERITY_TYPES.LOW,
  EKG_INTERPRETATION_SEVERITY_TYPES.null,
];

export const getHighestEKGInterpretationSeverityTypeFromInterpretations = (ints) => {
  const severityOrder = getHighestEKGInterpretationSeverityTypesOrder();
  let found = false;
  let i = 0;
  let highestSeverity;
  let interpretations = ints;

  if (interpretations === null) {
    interpretations = [];
  }
  const interpretationSeverities = interpretations.map((interpretation) => interpretation.severity);

  while (!found && i < severityOrder.length) {
    if (interpretationSeverities.includes(severityOrder[i])) {
      found = true;
      highestSeverity = severityOrder[i];
    }
    i += 1;
  }
  return highestSeverity;
};

export const getEKGInterpretationDisplayTextList = (intMap, int) => {
  if (int && Object.keys(intMap).length > 0) {
    const ints = int.map((i) => intMap[i.key].localizedDisplayText);
    return ints.join(', ');
  } else {
    return '';
  }
};

// eslint-disable-next-line
// https://github.com/alivecor/AliveECG-iOS/blob/3f156026e8f9f17fe71ce1ef7a4ef9d8ad0e3f1b/AliveECG/Classes/ACAlgorithmResultTheme.m#L79
export const EKG_ALGORITHM_DETERMINATION_TYPES = {
  // Expected results
  afib: EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.AFIB,
  tachycardia: EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.TACHYCARDIA,
  bradycardia: EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.BRADYCARDIA,
  normal: EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.NORMAL,
  noise: EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.UNREADABLE,
  unreadable: EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.UNREADABLE,
  unclassified: EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.UNCLASSIFIED,
  undetermined: EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.UNCLASSIFIED,
  'too short': EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.TOO_SHORT, // legacy support
  too_short: EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.TOO_SHORT,
  'too long': EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.TOO_LONG, // legacy support
  too_long: EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.TOO_LONG,
  none: EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.NO_ANALYSIS,
  unknown: EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.NO_ANALYSIS, // legacy support
  no_analysis: EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.NO_ANALYSIS,
  'sinus_rhythm,multiple_pacs':
    EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES['SINUS_RHYTHM,MULTIPLE_PACS'],
  'sinus_rhythm,wide_qrs': EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES['SINUS_RHYTHM,WIDE_QRS'],
  'sinus_rhythm,multiple_pvcs':
    EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES['SINUS_RHYTHM,MULTIPLE_PVCS'],
  sinus_rhythm: EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.SINUS_RHYTHM,
  normal_sinus_rhythm: EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.NORMAL_SINUS_RHYTHM,

  // CYA results
  '': EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.NO_ANALYSIS,
  [null]: EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.NO_ANALYSIS,
};

// eslint-disable-next-line
// https://github.com/alivecor/AliveECG-iOS/blob/3f156026e8f9f17fe71ce1ef7a4ef9d8ad0e3f1b/AliveECG/Classes/ACAlgorithmResultTheme.m#L217
export const getEKGAlgorithmDeterminationClassName = (ekgAlgorithmDetermination) => {
  const ekgAlgorithmDeterminationGroupType =
    EKG_ALGORITHM_DETERMINATION_TYPES[ekgAlgorithmDetermination];
  let colorClassName;

  switch (ekgAlgorithmDeterminationGroupType) {
    case EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.UNCLASSIFIED:
    case EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.TACHYCARDIA:
    case EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.BRADYCARDIA:
    case EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES['SINUS_RHYTHM,MULTIPLE_PACS']:
    case EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES['SINUS_RHYTHM,MULTIPLE_PVCS']:
      colorClassName = EKG_CLASSNAMES.ekgOther;
      break;
    case EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.NORMAL:
    case EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.SINUS_RHYTHM:
    case EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.NORMAL_SINUS_RHYTHM:
      colorClassName = EKG_CLASSNAMES.ekgNormal;
      break;
    case EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.AFIB:
    case EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES['SINUS_RHYTHM,WIDE_QRS']:
      colorClassName = EKG_CLASSNAMES.ekgAbnormal;
      break;
    case EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.NO_ANALYSIS:
    case EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.TOO_SHORT:
    case EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.TOO_LONG:
    case EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.UNREADABLE:
    default:
      colorClassName = EKG_CLASSNAMES.ekgNoAnalysis;
      break;
  }

  return colorClassName;
};

export const getEkgSeverityClassname = (ekgSeverity) => {
  let colorClassName;

  switch (ekgSeverity) {
    case EKG_CLASSNAMES.ekgNormal:
      colorClassName = EKG_CLASSNAMES.ekgNormal;
      break;
    case EKG_CLASSNAMES.ekgAbnormal:
      colorClassName = EKG_CLASSNAMES.ekgAbnormal;
      break;
    case EKG_CLASSNAMES.ekgOther:
      colorClassName = EKG_CLASSNAMES.ekgOther;
      break;
    case EKG_CLASSNAMES.ekgNoAnalysis:
      colorClassName = EKG_CLASSNAMES.ekgNoAnalysis;
      break;
    default:
      break;
  }

  return colorClassName;
};

export const getEKGAlgorithmDeterminationDisplayText = (
  ekgAlgorithmDetermination,
  algorithmPackage,
) => {
  const ekgAlgorithmDeterminationGroupType =
    EKG_ALGORITHM_DETERMINATION_TYPES[ekgAlgorithmDetermination];
  let displayText;

  switch (ekgAlgorithmDeterminationGroupType) {
    case EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.UNCLASSIFIED:
      displayText = 'Unclassified';
      break;
    case EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.NORMAL:
      displayText = 'Normal';
      break;
    case EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.AFIB:
      displayText =
        algorithmPackage === 'KAIv2' ? 'Atrial Fibrillation' : 'Possible Atrial Fibrillation';
      break;
    case EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.TACHYCARDIA:
      displayText = 'Tachycardia';
      break;
    case EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.BRADYCARDIA:
      displayText = 'Bradycardia';
      break;
    case EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.TOO_SHORT:
      displayText = 'Short';
      break;
    case EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.TOO_LONG:
      displayText = 'Long';
      break;
    case EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.UNREADABLE:
      displayText = 'Unreadable';
      break;
    case EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES['SINUS_RHYTHM,MULTIPLE_PACS']:
      displayText = 'Sinus Rhythm with Supraventricular Ectopy';
      break;
    case EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES['SINUS_RHYTHM,WIDE_QRS']:
      displayText = 'Sinus Rhythm with Wide QRS';
      break;
    case EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES['SINUS_RHYTHM,MULTIPLE_PVCS']:
      displayText = 'Sinus Rhythm with Premature Ventricular Contractions';
      break;
    case EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.SINUS_RHYTHM:
      displayText = algorithmPackage === 'KAIv2' ? 'Normal Sinus Rhythm' : 'Normal';
      break;
    case EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.NORMAL_SINUS_RHYTHM:
      displayText = algorithmPackage === 'KAIv2' ? 'Normal Sinus Rhythm' : 'Normal';
      break;
    case EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.NO_ANALYSIS:
    default:
      displayText = 'No Analysis';
      break;
  }

  return displayText;
};

export const getEKGAlgorithmDeterminationStackFilter = (ekgAlgorithmDetermination) => {
  const ekgAlgorithmDeterminationGroupType =
    EKG_ALGORITHM_DETERMINATION_TYPES[ekgAlgorithmDetermination];
  let ekgStackFilter;

  switch (ekgAlgorithmDeterminationGroupType) {
    case EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.NORMAL:
      ekgStackFilter = EKG_STACK_FILTERS.NORMAL;
      break;
    case EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.AFIB:
      ekgStackFilter = EKG_STACK_FILTERS.ABNORMAL;
      break;
    case EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.UNCLASSIFIED:
    case EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.TACHYCARDIA:
    case EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.BRADYCARDIA:
      ekgStackFilter = EKG_STACK_FILTERS.OTHER;
      break;
    case EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.TOO_SHORT:
    case EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.TOO_LONG:
    case EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.NO_ANALYSIS:
    case EKG_ALGORITHM_DETERMINATION_BUCKETED_TYPES.UNREADABLE:
    default:
      ekgStackFilter = EKG_STACK_FILTERS.NO_ANALYSIS;
      break;
  }

  return ekgStackFilter;
};

export const getEKGInterpretationSeverityClassName = (ekgInterpretationSeverity) => {
  let colorClassName;

  switch (ekgInterpretationSeverity) {
    case EKG_INTERPRETATION_SEVERITY_TYPES.HIGH:
    case EKG_INTERPRETATION_SEVERITY_TYPES.MEDIUM:
      colorClassName = EKG_CLASSNAMES.ekgAbnormal;
      break;
    case EKG_INTERPRETATION_SEVERITY_TYPES.LOW:
      colorClassName = EKG_CLASSNAMES.ekgNormal;
      break;
    case EKG_INTERPRETATION_SEVERITY_TYPES.null:
    default:
      colorClassName = EKG_CLASSNAMES.ekgNoAnalysis;
      break;
  }

  return colorClassName;
};

export const getEKGInterpretationSeverityStackFilter = (ekgInterpretationSeverity) => {
  let ekgStackFilter;

  switch (ekgInterpretationSeverity) {
    case EKG_INTERPRETATION_SEVERITY_TYPES.HIGH:
    case EKG_INTERPRETATION_SEVERITY_TYPES.MEDIUM:
      ekgStackFilter = EKG_STACK_FILTERS.ABNORMAL;
      break;
    case EKG_INTERPRETATION_SEVERITY_TYPES.LOW:
      ekgStackFilter = EKG_STACK_FILTERS.NORMAL;
      break;
    case EKG_INTERPRETATION_SEVERITY_TYPES.null:
    default:
      ekgStackFilter = EKG_STACK_FILTERS.NO_ANALYSIS;
      break;
  }

  return ekgStackFilter;
};
