import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import Grid from '@material-ui/core/Grid';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Box from '@material-ui/core/Box';
import { colors, fontSizes } from '../../../style/styleSettings';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiAccordionDetails-root': {
      marginLeft: theme.spacing(2),
      maxHeight:'340px',
      overflowY:'scroll'
    },
    '& .MuiAccordionSummary-content':{
      justifyContent: 'space-between',
      paddingTop: '12px !important'
    },
    '& .MuiIconButton-label':{
      color: `${colors.SPRUCE} !important`,
    },
    '& .MuiAccordionSummary-expandIcon': {
      transform: 'rotate(-90deg)'
    },
    '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
      transform: 'rotate(180deg)'
    }
  },
  sectionHeaderText:{
    fontSize: `${fontSizes.HEADING}`,
    fontWeight: '600 !important',
    color: `${colors.SESSION_BLACK} !important`,
  },
  headerText: {
    fontWeight: '600 !important',
    color: `${colors.SESSION_BLACK} !important`,
    fontSize: `${fontSizes.MEDIUM}`,
  },
  headerCollapse:{
    color: `${colors.SPRUCE} !important`,
    fontFamily: 'SF Pro Display !important',
    fontSize: `${fontSizes.BASE} !important`,
    fontWeight: 'bold !important'
  },
  surveyQa: {
    marginLeft: theme.spacing(2),
    '& .MuiGrid-item':{
        marginBottom: theme.spacing(1),
    },
    fontSize: `${fontSizes.BASE}`,
  },
}));

const SurveysComponent = () => {
  const [expand, setExpand]=useState(true)
  const classes = useStyles();
  return (
    <Box>
      <Accordion expanded={expand} className={classes.root}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          onClick={()=>setExpand(e=>!e)}
        >
          <Typography variant="h5" className={classes.sectionHeaderText}>
            Surveys
          </Typography>
          <Typography variant="h6" className={classes.headerCollapse}>
            {expand ? 'COLLAPSE' : 'EXPAND'}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container rowSpacing={1}>
            <Grid item xs={12}>
              <Typography variant="h6" className={classes.headerText}>
                09/02/2021 Heart Health Assessment
              </Typography>
            </Grid>
            <Grid
              container
              className={classes.surveyQa}
              rowSpacing={5}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12}>
                <p style={{ margin: 0 }}>Question:</p>
                <p>Answer</p>
              </Grid>
              <Grid item xs={12}>
                <p style={{ margin: 0 }}>Question:</p>
                <p>Answer</p>
              </Grid>
              <Grid item xs={12}>
                <p style={{ margin: 0 }}>Question:</p>
                <p>Answer</p>
              </Grid>
              <Grid item xs={12}>
                <p style={{ margin: 0 }}>Question:</p>
                <p>Answer</p>
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default SurveysComponent;
