import { combineReducers } from 'redux';

// Constants Types
import {
  TEAM_SETTINGS_FETCH_REQUEST,
  TEAM_SETTINGS_FETCH_SUCCESS,
  TEAM_SETTINGS_FETCH_FAILURE,
  TEAM_SETTINGS_CHANGES_REQUEST,
  TEAM_SETTINGS_CHANGES_SUCCESS,
  TEAM_SETTINGS_CHANGES_FAILURE,
  BUSINESS_HOURS_FETCH_REQUEST,
  BUSINESS_HOURS_FETCH_SUCCESS,
  BUSINESS_HOURS_FETCH_FAILURE,
  BUSINESS_HOURS_CHANGES_REQUEST,
  BUSINESS_HOURS_CHANGES_SUCCESS,
  BUSINESS_HOURS_CHANGES_FAILURE,
} from './types';

const initialState = {
  isRequesting: false,
  appointmentInfo: {},
  businessHours: {},
  error: {},
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [TEAM_SETTINGS_FETCH_REQUEST]: (state, action) => ({
    ...state,
    isRequesting: true,
  }),
  [TEAM_SETTINGS_FETCH_SUCCESS]: (state, action) => ({
    ...state,
    isRequesting: false,
    appointmentInfo: action.payload,
  }),
  [TEAM_SETTINGS_FETCH_FAILURE]: (state, action) => ({
    ...state,
    isRequesting: false,
    error: action.payload,
  }),
  [TEAM_SETTINGS_CHANGES_REQUEST]: (state, action) => ({
    ...state,
    isRequesting: true,
  }),
  [TEAM_SETTINGS_CHANGES_SUCCESS]: (state, action) => ({
    ...state,
    isRequesting: false,
    isSuccess:true
  }),
  [TEAM_SETTINGS_CHANGES_FAILURE]: (state, action) => ({
    ...state,
    isRequesting: false,
    error: action.payload,
  }),
  [BUSINESS_HOURS_FETCH_REQUEST]: (state, action) => ({
    ...state,
    isRequesting: true,
  }),
  [BUSINESS_HOURS_FETCH_SUCCESS]: (state, action) => ({
    ...state,
    isRequesting: false,
    businessHours: action.payload,
  }),
  [BUSINESS_HOURS_FETCH_FAILURE]: (state, action) => ({
    ...state,
    isRequesting: false,
    error: action.payload,
  }),
  [BUSINESS_HOURS_CHANGES_REQUEST]: (state, action) => ({
    ...state,
    isRequesting: true,
  }),
  [BUSINESS_HOURS_CHANGES_SUCCESS]: (state, action) => ({
    ...state,
    isRequesting: false,
  }),
  [BUSINESS_HOURS_CHANGES_FAILURE]: (state, action) => ({
    ...state,
    isRequesting: false,
    error: action.payload,
  }),
};

//
// Reducer
// ------------------------------------
const AppointmentNotifications = (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default combineReducers({
  appointmentNotifications: AppointmentNotifications,
});
