import React from 'react';

import styles from './TableHeader.module.css';
import { SORT_TYPES } from '../../../../constants/app';
import FilterListIcon from '@material-ui/icons/FilterList';
const TableHeader = (props) => {
  const {
    enableSort = true,
    label,
    filterActive,
    columnHeader,
    handleOnFilterButtonClick,
    handleOnSortClick,
    lastElement,
    sortBy,
    sortDirection,
    selectedPopover,
    Popover,
    noFilterPlaceholder = false,
  } = props;

  const cn = !lastElement ? styles.columnFilter : styles.lastColumnFilter;
  const activeCn = !filterActive ? styles.filterButton : styles.filterButtonActive;

  let sortDirectionIcon = <div className={styles.sortDirectionNone} />;

  if (enableSort && sortBy === columnHeader) {
    switch (sortDirection) {
      case SORT_TYPES.DESC:
        sortDirectionIcon = <div className={styles.sortDirectionDesc} />;
        break;
      case SORT_TYPES.ASC:
        sortDirectionIcon = <div className={styles.sortDirectionAsc} />;
        break;
      case null:
      default:
        break;
    }
  }

  return (
    <div className={styles.headerWithControls}>
      {enableSort && (
        <div className={styles.columnWrapper}>
          <div className={styles.columnHeader} onClick={() => handleOnSortClick(columnHeader)}>
            {label}
            {selectedPopover === columnHeader && (
              <div
                className={styles.popoverWrapper}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {Popover}
              </div>
            )}
          </div>
          {/* {sortDirectionIcon} */}
          {Popover && (
            <>
              <FilterListIcon
                onClick={() => handleOnFilterButtonClick(columnHeader)}
                data-action="filter"
                data-label={columnHeader}
              />
            </>
          )}
        </div>
      )}
      {!enableSort && <div className={styles.columnHeaderNoSort}>{label}</div>}

      {/* {noFilterPlaceholder && <div className={styles.columnFilter} />} */}
    </div>
  );
};

export default TableHeader;
