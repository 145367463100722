import React from 'react';
import Spinner from '../Shared/Spinner/Spinner';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const Statistics = (props) => {
  const { t } = useTranslation();
  const isLoading = useSelector((state) => state.appointments.statsloader);
  return (
    <React.Fragment>
      {isLoading ? (
        <div className="spinnerContainer margin_200">
          <Spinner></Spinner>
        </div>
      ) : (
        <div>
          <div className="row no-gutters">
            <div className="col-12 p-3 mt-3">
              <h5 className="caption mb-3">{t('Consultations')}</h5>
              <div className="row no-gutters">
                <div className="col-4">
                  <div>{t('Total')}</div>
                  <h5 className="head">{props.stats.totalAppointments || '-'}</h5>
                </div>
                <div className="col-4">
                  <div>{t('Completed')}</div>
                  <h5 className="head">{props.stats.totalAppointmentsCompleted || '-'}</h5>
                </div>
                <div className="col-4">
                  <div>{t('Canceled')}</div>
                  <h5 className="head">{props.stats.totalAppointmentsCancelled || '-'}</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="row no-gutters">
            <div className="col-12 p-3 mt-0">
              <h5 className="caption mb-3">{t('Consultation Type')}</h5>
              <div className="row no-gutters">
                <div className="col-4">
                  <div>{t('General')}</div>
                  <h5 className="head">{props.stats.totalConsulationGeneral || '-'}</h5>
                </div>
                {/* <div className="col-4">
                                        <div>Detailed</div>
                                        <h5 className="head">{props.stats.totalConsulationDetailed}</h5>
                                    </div> */}
                <div className="col-4">
                  <div>{t('Follow-up')}</div>
                  <h5 className="head">{props.stats.totalConsulationFollowup || '-'}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Statistics;
