import { connect } from 'react-redux';
import TeamMemberFile from './TeamMemberFile';
import { getDataSource } from '../../Store/reducers/app';
import { getFormValues } from 'redux-form';
import { DATASOURCES, FORMS } from '../../constants/app';
import {
  setInitialTeamMemberData,
  getTeamMemberFileData,
  handleOnEditTeamMemberSubmit,
  handleOnTeamMemberDeleteClick,
  getAppointmentNotificationsSettings,
  handleAppointmentNotificationsChange,
} from './module';

const mapActionCreators = {
  setInitialTeamMemberData,
  getTeamMemberFileData,
  handleOnEditTeamMemberSubmit,
  handleOnTeamMemberDeleteClick,
  getAppointmentNotificationsSettings,
  handleAppointmentNotificationsChange,
};

const mapStateToProps = (state) => ({
  dataIsFetching: state.teamMemberFile.dataIsFetching,
  dataLastUpdated: state.teamMemberFile.dataLastUpdated,
  selectedTeamMemberId: state.teamMemberFile.selectedTeamMemberId,
  physicians: getDataSource(state, DATASOURCES.PHYSICIANS),
  editMemberFormData: getFormValues(FORMS.EDIT_MEMBER_FORM)(state),
  me: getDataSource(state, DATASOURCES.ME),
  notification: getDataSource(state, DATASOURCES.MEMBER_NOTIFICATION_SETTINGS),
});

export default connect(mapStateToProps, mapActionCreators)(TeamMemberFile);
