import React from 'react'
import Rheostat from 'rheostat'

import styles from './Slider.module.css'

class Slider extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tempSelected: [ props.minValue, props.maxValue ],
    }
  }

  render() {
    const {
      min,
      max,
      handleOnChange,
    } = this.props
    const {
      tempSelected,
    } = this.state

    return (
      <React.Fragment>
        <div className={styles.slider}>
          
          <Rheostat
            min={min}
            max={max}
            values={tempSelected}
            onChange={valuesContainer => {
              handleOnChange(valuesContainer.values)
            }}
            onValuesUpdated={valuesContainer => {
              this.setState({
                tempSelected: valuesContainer.values,
              })
            }}
          />
        </div>
        <div className={styles.sliderValues}>
          <div className={styles.sliderValue}>
            <p>Start</p>
            <p>{tempSelected[0]}</p>
          </div>
          <div className={styles.sliderValue}>
            <p>End</p>
            <p>{tempSelected[1]}</p>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default Slider
