import { TextField, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles({
  root: {
    marginBottom: '20px',
    '& .MuiOutlinedInput-input': {
      padding: '10px 15px !important',
    },
    '& .MuiOutlinedInput-root': {
      border: '1px solid rgba(20, 42, 57, 0.16)',
    },
  },
});
function InputFieldComponent(props) {
  const classes = useStyles();
  return (
    <TextField
      className={classes.root}
      id={props.id}
      value={props.value}
      onChange={props.onChange}
      variant="outlined"
    />
  );
}

export default InputFieldComponent;
