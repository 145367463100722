/* eslint-disable */
import * as API from './../../Services/API/actions';
import moment from 'moment';
import { useTheme } from '@material-ui/core/styles';
import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Statistics from './statistics';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import PatientDetails from '../Appointments/Calendar/PatientDetails';
import { calcRowSeconds } from '../chart/utils';
import * as Lib from '../chart/Constants/RecordingConstants';
import _get from 'lodash.get';
import { useTranslation } from 'react-i18next';
import { getEKGAlgorithmDeterminationDisplayText } from '../../Utilities/recordingMaps';
import { useSelector } from 'react-redux';
import { Document, Page, pdfjs } from 'react-pdf';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import EcgLoader from '../Shared/EcgLoader';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useResizeDetector from 'use-resize-observer/polyfilled';
import debounce from 'lodash.debounce';
import Surveys from './SessionComponents/SurveysComponent';
import SessionComponents from './SessionComponents';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box div={3}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    //flexGrow: 1,
    width: '100%',
    //borderBottom: '1px solid #e8e8e8',
    backgroundColor: 'transparent',
    boxShadow: 'none !important',
  },
  paperroot: {
    flexGrow: 1,
    flexDirection: 'row',
    // justifyContent: "flex-end",
    boxShadow: 'none !important',
  },
  customTabRoot: {
    color: '#142A39',
    backgroundColor: '#fff !important',
    borderBottom: '1px solid #e8e8e8',
    padding: '5px',
    boxShadow: 'none !important',
    minWidth: 75,
    fontWeight: 700,
  },
  customTabIndicator: {
    backgroundColor: '#2d9f86',
    height: '4px',
  },
  blackGrid: {
    fontWeight: '600',
    alignItems: 'center',
    height: '55px',
    padding: theme.spacing(1),
    background: '#E2E7E3',
    color: '#000000',
    lineHeight: '40px',
  },
  tabRoot: {
    background: '#fff !important',
    fontSize: '14px',
    fontWeight: 'bold',
    opacity: '0.54',
    minWidth: 75,
    // fontFamily: ["Work Sans"].join(","),
    fontFamily: 'Work Sans !important',
    textTransform: 'uppercase !important',
    boxShadow: 'none !important',
  },
  label: {
    fontFamily: 'Work Sans !important',
    fontWeight: '700 !important',
  },
  tabvaluestyle: {
    // minWidth: "130px",
  },
  tabvaluesmallstyle: {
    minWidth: 'auto',
  },
  recordingInfo: {
    zIndex: 1,
    padding: '8px 10px',
    backgroundColor: '#fff',
    opacity: 0.8,
    width: 'fit-content',
    display: '-webkit-box' && '-moz-box' && '-ms-flexbox' && '-webkit-flex' && 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    position: 'absolute',
    right: '10px',
    margin: '20px',
  },
  recordingInfospan: {
    // width: "30px",
    paddingRight: '20px',
    color: '#000',
  },
  recordingInfospana: {
    // width: "30px",
    paddingRight: '20px',
    color: '#4183c4 !important',
  },
  recordingInfospanfirstChild: {
    width: '30px',
    paddingLeft: '0px',
  },
}));

const contentwrapperstyles = {
  height: '500px',
  borderRadius: '4px',
  boxShadow: '0px 2px 3px 0px light-grey',
  backgroundColor: '#FFFFFF',
  position: 'relative',
};

function useWidth() {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || 'xs'
  );
}

const ScrollableTabsButtonAuto = (props) => {
  const teamId = useSelector((state) => state.user.team.id);
  const clinicianId = useSelector((state) => state.user.profile.id);
  const mediawidth = useWidth();
  const [numPages, setNumPages] = useState(null);
  const [pdfwidth, setpdfwidth] = useState(1000);
  const [ekgdivwidth, setekgdivwidth] = useState(1000);

  function handleekgdivResize({ width }) {
    if (ekgdivwidth !== width && width !== 0) {
      setekgdivwidth(width);
    }
  }

  function handleResize({ width }) {
    if (pdfwidth !== width && width !== 0) {
      setpdfwidth(width);
    }
  }
  const pdfref = useRef(null);
  const ekgref = useRef(null);

  const delay = 30;

  useResizeDetector({
    ref: pdfref,
    onResize: debounce(handleResize, delay, { maxWait: 100 }),
  });

  useResizeDetector({
    ref: ekgref,
    onResize: debounce(handleekgdivResize, delay, { maxWait: 100 }),
  });

  function handlecommunicationResize({ height }) {
    console.log('comunicationheight', height);
    if (comunicationheight !== height) {
      setcomunicationheight(height);
    }
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const [startDate] = useState(new Date());
  const pattern = 'YYYY-MM-DDT00:00:00.000';
  const eodpattern = 'YYYY-MM-DDT23:59:00.000';
  const summaryReportRequest = {
    endDateTime: moment(endOfMonth(startDate)).format(eodpattern),
    patientId:
      _get(props, 'patientDetails.patient.patientId') ||
      _get(props, 'event.patient.patientId') ||
      '-',
    startDateTime: moment(startOfMonth(startDate)).format(pattern),
  };

  const [pdfdata, setpdfdata] = useState('');

  // useEffect(() => {
  //   API.getbphistorypdf(teamId, clinicianId, summaryReportRequest).subscribe(
  //     (res) => {
  //       console.log('res', res);
  //       setpdfdata(res.data.result);
  //       // res.data.result !== null &&
  //     },
  //     (error) => console.log(error),
  //   );
  // }, []);

  const classes = useStyles();
  const { t } = useTranslation();
  const MIN_SECONDS = 1;
  const mainContentWidth = ekgdivwidth;
  const rowSeconds =
    calcRowSeconds(mainContentWidth) > 0 ? calcRowSeconds(mainContentWidth) : MIN_SECONDS;
  const ekgWidth = rowSeconds * Lib.PX_PER_SECOND + 18;
  return (
    <div className="row">
      <div className="col-12 p-0">
        <div className={classes.root + ' position_rel'}>
          {/* <PatientDetails
              patientDetails={props.patientDetails}
              appointmentId={props.appointmentId}
              stepper={props.step}
              {...props}
            /> */}
          <SessionComponents patientDetails={props.patientDetails} {...props} />
        </div>
      </div>
    </div>
  );
};
export default ScrollableTabsButtonAuto;
