import React from 'react';
import styles from './comingSoon.module.css';
import DashboardIcon from '../../Assets/images/poll.svg';
import { useHistory } from 'react-router';
import { ROUTES } from '../../constants/app';
// import { ROUTES } from '../../Components/chart/Constants';

const NotFoundPage = ({ type }) => {
  const history = useHistory();
  const handleRouteToDashboard = () => {
    history.push(ROUTES.ROOT);
  };
  return (
    <div data-page="not-found-page" className={styles.NotFoundPage}>
      <h3>COMING SOON</h3>
      <p>Head back to the Dashboard.</p>
      <button className={styles.dashboardBtn} onClick={handleRouteToDashboard}>
        <DashboardIcon />
        Dashboard
      </button>
    </div>
  );
};

export default NotFoundPage;
