import React from 'react';
import Select from 'react-select';

import styles from './SelectDropdown.module.css';

class SelectDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.input = null;
  }

  handleOnChange = (selected) => {
    const { onChange } = this.props;

    const id = selected && selected.id;
    if (typeof onChange === 'function') {
      if (id) {
        onChange(id);
      } else {
        onChange(selected);
      }
    }
    this.input.blur();
  };

  defaultFilterOption = (option, input) => {
    const label = option.label.toLowerCase();
    const searchInputParts = input.toLowerCase().split(' ');
    return searchInputParts.every((text) => label.includes(text));
  };

  render() {
    const {
      title,
      options,
      onChange,
      value,
      placeholder,
      isLoading = false,
      isClearable = true,
      backspaceRemovesValue = true,
      noOptionsMessage,
      getOptionLabel,
      getOptionFilter = this.defaultFilterOption,
      getOptionValue,
      maxMenuHeight,
      className = 'react-select-filter-selector',
      classNamePrefix = 'react-select-filter-selector',
      components,
      menuIsOpen,
      onInputChange,
      inputValue,
      onFocus,
      onBlur,
      onMenuOpen,
      autoFocus,
      disabled,
      titleClassName = '',
      customStyles = {},
      ...rest
    } = this.props;

    return (
      <div className={styles.filter}>
        {title && <h3 className={titleClassName}>{title}</h3>}
        <Select
          className={className}
          classNamePrefix={classNamePrefix}
          ref={(input) => {
            this.input = input;
          }}
          // filterOption={getOptionFilter}
          value={value}
          maxMenuHeight={maxMenuHeight}
          autoFocus={autoFocus}
          isLoading={isLoading}
          isClearable={isClearable}
          inputValue={inputValue}
          backspaceRemovesValue={backspaceRemovesValue}
          placeholder={placeholder}
          onChange={this.handleOnChange}
          onMenuOpen={onMenuOpen}
          onFocus={onFocus}
          onBlur={onBlur}
          onInputChange={onInputChange}
          options={options}
          noOptionsMessage={() => noOptionsMessage}
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
          components={components}
          menuIsOpen={menuIsOpen}
          isDisabled={disabled}
          styles={customStyles}
          // {...rest}
        />
      </div>
    );
  }
}

export default SelectDropdown;
