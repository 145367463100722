export const headCells = [
  {
    id: 'participantLastName',
    label: 'Member',
  },
  {
    id: 'nextSession',
    label: 'Next Session',
  },
  {
    id: 'bloodPressure',
    label: 'Blood Pressure',
  },
  {
    id: 'lastLogin',
    label: 'Last Login',
  },
  {
    id: 'sponsor',
    label: 'Sponsor',
  },
  {
    id: 'programList',
    label: 'Program',
  },
  {
    id: 'status',
    label: 'Status',
  },
  {
    id: 'nextAction',
    label: 'Next Action',
  },
];
export const adminHeadCells = [
  {
    id: 'coachLastName',
    label: 'Coach',
  },
  {
    id: 'participantLastName',
    label: 'Member',
  },
  {
    id: 'nextSession',
    label: 'Next Session',
  },
  {
    id: 'bloodPressure',
    label: 'Blood Pressure',
  },
  {
    id: 'lastLogin',
    label: 'Last Login',
  },
  {
    id: 'sponsor',
    label: 'Sponsor',
  },
  {
    id: 'programList',
    label: 'Program',
  },
  {
    id: 'status',
    label: 'Status',
  },
];

export const memberFilters = {
  status: [
    {
      id: 'status1',
      name: 'Active',
      type: 'status',
    },
    {
      id: 'status2',
      name: 'Inactive',
      type: 'status',
    },
    {
      id: 'status3',
      name: 'Non complaint',
      type: 'status',
    },
    {
      id: 'status4',
      name: 'Terminated',
      type: 'status',
    },
    {
      id: 'status5',
      name: 'Unscheduled',
      type: 'status',
    },
    {
      id: 'status6',
      name: 'On hold',
      type: 'status',
    },
  ],
  bloodPressure: [
    {
      id: 'blood1',
      name: 'At goal',
      type: 'bloodPressure',
    },
    {
      id: 'blood2',
      name: 'Elevated',
      type: 'bloodPressure',
    },
    {
      id: 'blood3',
      name: 'HTN 1',
      type: 'bloodPressure',
    },
    {
      id: 'blood4',
      name: 'HTN 2',
      type: 'bloodPressure',
    },
    {
      id: 'blood5',
      name: 'HTN crisis',
      type: 'bloodPressure',
    },
  ],
  program: [
    {
      id: 'program1',
      name: 'Hypertension',
      type: 'program',
    },
    {
      id: 'program2',
      name: 'AFib',
      type: 'program',
    },
    {
      id: 'program3',
      name: 'AFib/HTN',
      type: 'program',
    },
  ],
  sponsor: [
    {
      id: 'sponsor1',
      name: 'Amazon',
      type: 'sponsor',
    },
    {
      id: 'sponsor2',
      name: 'Etsy',
      type: 'sponsor',
    },
    {
      id: 'sponsor3',
      name: 'Uber',
      type: 'sponsor',
    },
    {
      id: 'sponsor4',
      name: 'HBO',
      type: 'sponsor',
    },
    {
      id: 'sponsor5',
      name: 'Drizly',
      type: 'sponsor',
    },
  ],
};

export const headerInfoIconModalData = {
  status: [
    {
      title: 'Active',
      body: 'Member has been engaging with the app over a period of time.',
    },
    {
      title: 'Inactive',
      body: 'Member hasn’t logged into the app over a period of time.',
    },
    {
      title: 'Nocompliant',
      body: 'Member hasn’t engaged with the app over a period of time.',
    },
    {
      title: 'Terminated',
      body: 'Member is terminated because they’re no longer eligible, were inactive for too long, or do not****',
    },
    {
      title: 'Unscheduled',
      body: 'User hasn’t scheduled their next session with a coach after being reminded.',
    },
    {
      title: 'Onhold',
      body: 'User has told coach they will be pausing the program for a period of time but would like to resume.',
    },
  ],
  bloodPressure: [
    {
      title: 'Low',
      body: '< 90 and < 60',
    },
    {
      title: 'Normal',
      body: '< 120 and < 80',
    },
    {
      title: 'Elevated',
      body: '120 - 129 and < 80',
    },
    {
      title: 'Hypertension Stage 1',
      body: '130 - 139 OR 80 - 89',
    },
    {
      title: 'Hypertension Stage 2',
      body: '140 or higher OR 90 or higher',
    },
    {
      title: 'Hypertensive Crisis',
      body: '> 180 AND/OR > 120',
    },
  ],
};
