import React from 'react';
import { Column } from 'react-virtualized';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import Table from '../components/Table';
import TableStyles from '../components/Table/Table.module.css';
import TableHeader from '../components/TableHeader';
import { DropdownSearchPopover, ListPopover, SearchPopover } from '../../Popovers';
import styles from './TeamMembersTable.module.css';
import {
  TEAM_MEMBERS_TABLE_COLUMN_HEADERS,
  TABLE_CHECKBOX_WIDTH,
  getPermissionOptionDisplayTextFromBoolean,
} from '../../../constants/app';
import { getPhysicianName, getName } from '../../../Utilities/Utilities';
import EcgLoader from '../../Shared/EcgLoader';
import { countryList, RegionList } from '../../Forms/constants';

const CustomSwitch = withStyles({
  switchBase: {
    // color: '#2D9F86',
    '&$checked': {
      color: '#2D9F86',
    },
    '&$checked + $track': {
      backgroundColor: '#2D9F86',
    },
  },
  checked: {},
  track: {},
})(Switch);

class TeamMembersTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: {},
    };
  }

  static getDerivedStateFromProps(props, s) {
    const state = s;

    if (state.selected !== props.selected) {
      state.selected = props.selected;
    }

    return state;
  }

  handleOnSelectionClick = (selected) => {
    const { onSelectionClick } = this.props;

    this.setState({
      selected,
    });

    if (onSelectionClick) {
      onSelectionClick(selected);
    }
  };

  handleToggleChange = (e, rowData) => {
    e.preventDefault();
    const formData = rowData;
    formData.canManageAvailability = e.target.checked;
    this.props.handleOnEditTeamMember(formData);
    // this.props.handleOnEditTeamMember({ ...rowData, canManageAvailability: e.target.checked })
    // this.props.handleOnClickTeamMeamber('TEAM_MEMBERS')
  };
  render() {
    const {
      noDataText = 'No team members found.',
      enableLoader,
      showLoader,
      showRowSelection = false,
      data,
      width,
      onRowClick,
      selectedIndex,
      sortBy,
      sortDirection,

      filterName,
      filterEmail,
      filterCountry,
      filterRegion,
      filterAdminPermission,
      filterPhysicianPermission,
      handleOnFilterButtonClick,
      handleOnFilterChange,
      handleOnSortClick,
      handleOnOutsidePopoverClick,
      handleOnPopoverKeyUp,
      selectedPopover,

      physicians,
      selectedId,

      selectedEmail,
      selectedCountry,
      selectedRegion,

      permissionOptions,
      selectedAdminPermissionId,
      selectedPhysicianPermissionId,
      selectedTeamMembersCanManageAvailabilityId,
      showTKFeatureEnabled,
      me,
    } = this.props;
    const { selected } = this.state;

    const isAnySelected = Object.keys(selected).some((key) => selected[key] === true);
    const myId = me.data && me.data.profile.id;
    const ROW_HEIGHT = 50;
    const COUNTRY_WIDTH = 150;
    const ADMIN_PERMISSION_WIDTH = 150;
    const PHYSICIAN_PERMISSION_WIDTH = 150;
    let remainingWidth = width - ADMIN_PERMISSION_WIDTH - PHYSICIAN_PERMISSION_WIDTH;
    let emailPopoverClassname = styles.emailPopover;
    let namePopoverClassname = styles.namePopover;

    if (showRowSelection) {
      remainingWidth -= TABLE_CHECKBOX_WIDTH;
      emailPopoverClassname = styles.emailPopoverWithSelection;
      namePopoverClassname = styles.namePopoverWithSelection;
    }

    const emailWidth = remainingWidth / 2;
    const nameWidth = remainingWidth / 2;
    const regionWidth = 150;

    let selectedPhysician;
    let physiciansOptions;

    if (physicians && physicians.data) {
      physiciansOptions = physicians.data.sortedByLastName.map((id) => physicians.data.byId[id]);
      selectedPhysician = selectedId && physicians.data.byId[selectedId];
    }
    return (
      <React.Fragment>
        {!showLoader || showLoader ? (
          <div className={styles.teamMembersTable}>
            <Table
              noDataText={noDataText}
              enableLoader={enableLoader}
              showLoader={showLoader}
              // headerHeight={35}
              showRowSelection={showRowSelection}
              selected={selected}
              rowHeight={ROW_HEIGHT}
              uniqueKey="id"
              hasTableActions={isAnySelected}
              onSelectionClick={this.handleOnSelectionClick}
              onRowClick={onRowClick}
              scrollToIndex={selectedIndex}
              data={data}
              sortBy={sortBy}
              sortDirection={sortDirection}
            >
              <Column
                className={TableStyles.column}
                label="Name"
                dataKey={TEAM_MEMBERS_TABLE_COLUMN_HEADERS.NAME}
                width={nameWidth}
                headerRenderer={(headerProps) => {
                  const { label } = headerProps;

                  return (
                    <TableHeader
                      label={label}
                      columnHeader={TEAM_MEMBERS_TABLE_COLUMN_HEADERS.NAME}
                      handleOnSortClick={handleOnSortClick}
                      handleOnFilterButtonClick={handleOnFilterButtonClick}
                      filterActive={filterName}
                      sortBy={sortBy}
                      sortDirection={sortDirection}
                      selectedPopover={selectedPopover}
                      Popover={
                        <div className={namePopoverClassname}>
                          <DropdownSearchPopover
                            label={label}
                            noResultsMessage="No coach found"
                            handleOnOutsidePopoverClick={handleOnOutsidePopoverClick}
                            placeholder="Enter Name"
                            isLoading={physicians && physicians.isFetching}
                            options={physiciansOptions}
                            value={selectedPhysician}
                            onChange={(physician) =>
                              handleOnFilterChange(
                                TEAM_MEMBERS_TABLE_COLUMN_HEADERS.NAME,
                                physician,
                              )
                            }
                            getOptionLabel={(p) => getName(p.firstName, p.lastName, '')}
                            getOptionValue={(d) => d.id}
                          />
                        </div>
                      }
                    />
                  );
                }}
                cellRenderer={({ dataKey, rowData }) => {
                  const physicianName = getPhysicianName(rowData.firstName, rowData.lastName, '');

                  return (
                    <div className={TableStyles.columnContent}>
                      <p className={TableStyles.primaryText}>{physicianName}</p>
                    </div>
                  );
                }}
              />
              <Column
                className={TableStyles.column}
                label="Email"
                dataKey={TEAM_MEMBERS_TABLE_COLUMN_HEADERS.EMAIL}
                width={emailWidth}
                headerRenderer={(headerProps) => {
                  const { label } = headerProps;

                  return (
                    <TableHeader
                      label={label}
                      columnHeader={TEAM_MEMBERS_TABLE_COLUMN_HEADERS.EMAIL}
                      handleOnSortClick={handleOnSortClick}
                      handleOnFilterButtonClick={handleOnFilterButtonClick}
                      filterActive={filterEmail}
                      sortBy={sortBy}
                      sortDirection={sortDirection}
                      selectedPopover={selectedPopover}
                      Popover={
                        <div className={emailPopoverClassname}>
                          <DropdownSearchPopover
                            label={label}
                            noResultsMessage="No email found"
                            handleOnOutsidePopoverClick={handleOnOutsidePopoverClick}
                            placeholder="Enter Email"
                            isLoading={physicians && physicians.isFetching}
                            options={physiciansOptions}
                            value={selectedPhysician}
                            onChange={(email) =>
                              handleOnFilterChange(TEAM_MEMBERS_TABLE_COLUMN_HEADERS.EMAIL, email)
                            }
                            getOptionLabel={(p) => p.email || ''}
                            getOptionValue={(d) => d.id}
                          />
                        </div>
                      }
                    />
                  );
                }}
                cellRenderer={({ dataKey, rowData }) => (
                  <div className={TableStyles.centeredColumnContent}>
                    <p className={TableStyles.primaryText}>{rowData[dataKey]}</p>
                  </div>
                )}
              />
              <Column
                className={TableStyles.column}
                label="Country"
                dataKey={TEAM_MEMBERS_TABLE_COLUMN_HEADERS.COUNTRY}
                width={COUNTRY_WIDTH}
                headerRenderer={(headerProps) => {
                  const { label } = headerProps;

                  return (
                    <TableHeader
                      label={label}
                      columnHeader={TEAM_MEMBERS_TABLE_COLUMN_HEADERS.COUNTRY}
                      handleOnSortClick={handleOnSortClick}
                      handleOnFilterButtonClick={handleOnFilterButtonClick}
                      filterActive={filterCountry}
                      sortBy={sortBy}
                      sortDirection={sortDirection}
                      selectedPopover={selectedPopover}
                      Popover={
                        <div className={styles.countryPopover}>
                          <DropdownSearchPopover
                            label={label}
                            noResultsMessage="No country found"
                            handleOnOutsidePopoverClick={handleOnOutsidePopoverClick}
                            placeholder="Enter Country"
                            options={countryList}
                            value={selectedCountry}
                            onChange={(country) =>
                              handleOnFilterChange(
                                TEAM_MEMBERS_TABLE_COLUMN_HEADERS.COUNTRY,
                                country,
                              )
                            }
                            getOptionLabel={(p) => p.value || ''}
                            getOptionValue={(d) => d.value}
                          />
                        </div>
                      }
                    />
                  );
                }}
                cellRenderer={({ dataKey, rowData }) => (
                  <div className={TableStyles.centeredColumnContent}>
                    <p className={TableStyles.primaryText}>{rowData[dataKey]}</p>
                  </div>
                )}
              />
              <Column
                className={TableStyles.column}
                label="Region"
                dataKey={TEAM_MEMBERS_TABLE_COLUMN_HEADERS.REGION}
                width={regionWidth}
                headerRenderer={(headerProps) => {
                  const { label } = headerProps;

                  return (
                    <TableHeader
                      label={label}
                      columnHeader={TEAM_MEMBERS_TABLE_COLUMN_HEADERS.REGION}
                      handleOnSortClick={handleOnSortClick}
                      handleOnFilterButtonClick={handleOnFilterButtonClick}
                      filterActive={filterRegion}
                      sortBy={sortBy}
                      sortDirection={sortDirection}
                      selectedPopover={selectedPopover}
                      Popover={
                        <div className={styles.regionPopover}>
                          <DropdownSearchPopover
                            label={label}
                            noResultsMessage="No region found"
                            handleOnOutsidePopoverClick={handleOnOutsidePopoverClick}
                            placeholder="Enter Region"
                            options={Object.values(RegionList)}
                            value={selectedRegion}
                            onChange={(region) =>
                              handleOnFilterChange(TEAM_MEMBERS_TABLE_COLUMN_HEADERS.REGION, region)
                            }
                            getOptionLabel={(p) => p.name?.toLowerCase() || ''}
                            getOptionValue={(d) => d.value}
                          />
                        </div>
                      }
                    />
                  );
                }}
                cellRenderer={({ dataKey, rowData }) => (
                  <div className={TableStyles.centeredColumnContent}>
                    <p className={TableStyles.primaryText} style={{ textTransform: 'capitalize' }}>
                      {rowData[dataKey]?.length &&
                        RegionList[rowData[dataKey][0]]?.name?.toLowerCase()}
                    </p>
                  </div>
                )}
              />
              <Column
                className={TableStyles.column}
                label="Admin"
                dataKey={TEAM_MEMBERS_TABLE_COLUMN_HEADERS.ADMIN_PERMISSION}
                width={ADMIN_PERMISSION_WIDTH}
                headerRenderer={(headerProps) => {
                  const { label } = headerProps;

                  return (
                    <TableHeader
                      label={label}
                      columnHeader={TEAM_MEMBERS_TABLE_COLUMN_HEADERS.ADMIN_PERMISSION}
                      handleOnSortClick={handleOnSortClick}
                      handleOnFilterButtonClick={handleOnFilterButtonClick}
                      filterActive={filterAdminPermission}
                      sortBy={sortBy}
                      sortDirection={sortDirection}
                      selectedPopover={selectedPopover}
                      Popover={
                        <div className={styles.adminPermissionPopover}>
                          <ListPopover
                            list={permissionOptions}
                            selected={selectedAdminPermissionId}
                            getOptionLabel={(d) => d.label}
                            getOptionValue={(d) => d.id}
                            handleOnClick={(id) =>
                              handleOnFilterChange(
                                TEAM_MEMBERS_TABLE_COLUMN_HEADERS.ADMIN_PERMISSION,
                                id,
                              )
                            }
                            handleOnOutsidePopoverClick={handleOnOutsidePopoverClick}
                          />
                        </div>
                      }
                    />
                  );
                }}
                cellRenderer={({ dataKey, rowData }) => {
                  const { isAdmin } = rowData.permissions;

                  return (
                    <div className={TableStyles.centeredColumnContent}>
                      <p className={styles[isAdmin ? 'hasPermission' : 'doesNotHavePermission']}>
                        {getPermissionOptionDisplayTextFromBoolean(isAdmin)}
                      </p>
                    </div>
                  );
                }}
              />
              <Column
                className={TableStyles.column}
                label="Coach"
                dataKey={TEAM_MEMBERS_TABLE_COLUMN_HEADERS.PHYSICIAN_PERMISSION}
                width={PHYSICIAN_PERMISSION_WIDTH}
                headerRenderer={(headerProps) => {
                  const { label } = headerProps;

                  return (
                    <TableHeader
                      label={label}
                      columnHeader={TEAM_MEMBERS_TABLE_COLUMN_HEADERS.PHYSICIAN_PERMISSION}
                      handleOnSortClick={handleOnSortClick}
                      handleOnFilterButtonClick={handleOnFilterButtonClick}
                      filterActive={filterPhysicianPermission}
                      sortBy={sortBy}
                      sortDirection={sortDirection}
                      selectedPopover={selectedPopover}
                      Popover={
                        <div className={styles.physicianPermissionPopover}>
                          <ListPopover
                            list={permissionOptions}
                            selected={selectedPhysicianPermissionId}
                            getOptionLabel={(d) => d.label}
                            getOptionValue={(d) => d.id}
                            handleOnClick={(id) =>
                              handleOnFilterChange(
                                TEAM_MEMBERS_TABLE_COLUMN_HEADERS.PHYSICIAN_PERMISSION,
                                id,
                              )
                            }
                            handleOnOutsidePopoverClick={handleOnOutsidePopoverClick}
                          />
                        </div>
                      }
                    />
                  );
                }}
                cellRenderer={({ dataKey, rowData }) => {
                  const { isPhysician } = rowData.permissions;
                  return (
                    <div className={TableStyles.centeredColumnContent}>
                      <p
                        className={styles[isPhysician ? 'hasPermission' : 'doesNotHavePermission']}
                      >
                        {getPermissionOptionDisplayTextFromBoolean(isPhysician)}
                      </p>
                    </div>
                  );
                }}
              />
              {showTKFeatureEnabled && (
                <Column
                  className={TableStyles.column}
                  label="Manages Availability"
                  dataKey={TEAM_MEMBERS_TABLE_COLUMN_HEADERS.MANAGE_AVAILABILITY}
                  width={ADMIN_PERMISSION_WIDTH}
                  headerRenderer={(headerProps) => {
                    const { label } = headerProps;

                    return (
                      <TableHeader
                        label={label}
                        columnHeader={TEAM_MEMBERS_TABLE_COLUMN_HEADERS.MANAGE_AVAILABILITY}
                        handleOnSortClick={handleOnSortClick}
                        handleOnFilterButtonClick={handleOnFilterButtonClick}
                        filterActive={filterAdminPermission}
                        selectedPopover={selectedPopover}
                        disable={true}
                        Popover={
                          <div className={styles.adminPermissionPopover}>
                            <ListPopover
                              list={permissionOptions}
                              selected={selectedTeamMembersCanManageAvailabilityId}
                              getOptionLabel={(d) => d.label}
                              getOptionValue={(d) => d.id}
                              handleOnClick={(id) =>
                                handleOnFilterChange(
                                  TEAM_MEMBERS_TABLE_COLUMN_HEADERS.MANAGE_AVAILABILITY,
                                  id,
                                )
                              }
                              handleOnOutsidePopoverClick={handleOnOutsidePopoverClick}
                            />
                          </div>
                        }
                      />
                    );
                  }}
                  cellRenderer={({ dataKey, rowData }) => (
                    <div
                      onClick={(e) => e.stopPropagation()}
                      className={TableStyles.centeredColumnContent}
                    >
                      <CustomSwitch
                        checked={rowData[dataKey]}
                        onChange={(e) => this.handleToggleChange(e, rowData)}
                        name="adminToggle"
                        disabled={rowData.id === myId}
                      />
                    </div>
                  )}
                />
              )}
            </Table>
          </div>
        ) : (
          <EcgLoader />
        )}
      </React.Fragment>
    );
  }
}

export default TeamMembersTable;
