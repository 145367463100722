import React, { forwardRef } from 'react';
import { useSelector } from 'react-redux';

const Authorize = forwardRef((props, ref) => {
  const canManageAvailability = useSelector((state) => state.user.profile.canManageAvailability);

  return (
    <React.Fragment ref={ref}>
      {/* {localStorage.getItem("kardiapro_admin_permission") === "false" ? ( */}
      {canManageAvailability === false ? <></> : props.children}
    </React.Fragment>
  );
});

export default Authorize;
