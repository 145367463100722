import { useState, useRef } from 'react';

const useJoiningTimer = (initialState = 0) => {
  const [joiningtimer, setjoiningTimer] = useState(initialState);
  const [isActive, setIsActive] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const countRef = useRef(null);

  const handlejoiningStart = () => {
    setIsActive(true);
    setIsPaused(true);
    countRef.current = setInterval(() => {
      setjoiningTimer((timer) => timer + 1);
    }, 1000);
  };

  const handlejoiningPause = () => {
    clearInterval(countRef.current);
    setIsPaused(false);
  };

  const handlejoiningResume = () => {
    setIsPaused(true);
    countRef.current = setInterval(() => {
      setjoiningTimer((timer) => timer + 1);
    }, 1000);
  };

  const handlejoiningReset = () => {
    clearInterval(countRef.current);
    setIsActive(false);
    setIsPaused(false);
    setjoiningTimer(0);
  };

  return {
    joiningtimer,
    isActive,
    isPaused,
    handlejoiningStart,
    handlejoiningPause,
    handlejoiningResume,
    handlejoiningReset,
  };
};

export default useJoiningTimer;
