import React from 'react';
import TablePagination from '@material-ui/core/TablePagination';

const MaterialPagination = (props) => {
  const { current = 1, total, onChange, onSizeChange, pageSize = 10 } = props;
  if (!current || !total || total === 1) return null;

  return (
    <TablePagination
      component="div"
      count={total}
      rowsPerPage={pageSize}
      page={current - 1}
      backIconButtonProps={{
        'aria-label': 'previous page',
      }}
      nextIconButtonProps={{
        'aria-label': 'next page',
      }}
      onChangePage={(e, page) => onChange(page + 1)}
      rowsPerPageOptions={onSizeChange ? [5, 10, 25, 50] : [10]}
      onChangeRowsPerPage={onSizeChange}
    />
  );
};

export default MaterialPagination;
