import React from 'react';
import Select from 'react-select';

import styles from '../../Forms.module.css';

export default (props) => {
  const {
    input,
    meta: { error },
    label,
    getLabel,
    value,
    options,
    onChange,
    placeholder,
    disabled,
    isLoading = false,
    isClearable = true,
    backspaceRemovesValue = true,
    noOptionsMessage,
    getOptionLabel,
    getOptionValue,
    className = 'react-select-form-selector',
    classNamePrefix = 'react-select-form-selector',
    ...rest
  } = props;

  const inputComponent =
    typeof getLabel === 'function' ? getLabel() : <span className={styles.label}>{label}</span>;

  return (
    <label className={styles.inputGroup} htmlFor={input.name}>
      {inputComponent}
      <Select
        className={className}
        classNamePrefix={classNamePrefix}
        // ref={inputRef => { this.input = inputRef }}
        isLoading={isLoading}
        isClearable={isClearable}
        isDisabled={disabled}
        backspaceRemovesValue={backspaceRemovesValue}
        placeholder={placeholder}
        value={input.value}
        onChange={(v) => input.onChange(v)}
        onBlur={() => input.onBlur(input.value)}
        options={options}
        noOptionsMessage={() => noOptionsMessage}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        {...rest}
      />
      {error && <span className={styles.error}>{error}</span>}
    </label>
  );
};
