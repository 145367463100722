import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import Plotly from 'plotly.js';
import createPlotlyComponent from 'react-plotly.js/factory';
import useMediaQuery from '@material-ui/core/useMediaQuery';
const Plot = createPlotlyComponent(Plotly);

function useWidth() {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || 'xs'
  );
}

const DonutChart = (props) => {
  const txtcolor = '#000000';
  const backgrndcolor = '#FFFFFF';
  const mediawidth = useWidth();

  const checkValues = (vl) => {
    return vl === 0;
  };

  let pie_data = [
    {
      // values: [24, 18, 2, 9],
      values:
        props.values && props.values.every(checkValues) ? [1, 1, 1, 1, 1, 1, 1] : props.values,
      labels: [
        'Closed',
        'Completed',
        'Cancelled by doctor',
        'Cancelled by patient',
        'Scheduled',
        'In progress',
        'Cancelled by admin',
      ],
      type: 'pie',
      hole: 0.8,
      name: 'Appointments',
      textinfo: 'none',
      hoverinfo: 'name+value',
      // hoverinfo: "labels",
      marker: {
        colors: ['#415B6A', '#6BC1B6', '#FDC246', '#F25F5F', '#FF7300', '#885FA5'],
      },
    },
  ];

  return (
    <>
      <Plot
        data={pie_data}
        layout={{
          annotations: [
            {
              font: {
                size: 20,
              },
              showarrow: false,
              textinfo: 'none',
              text: `${props.totalAppointments}<br>Total<br>Sessions`,
            },
          ],
          // hovermode: "closest",
          height: mediawidth === 'xl' || mediawidth === 'lg' || mediawidth === 'md' ? 280 : 200,
          width: mediawidth === 'xl' || mediawidth === 'lg' || mediawidth === 'md' ? 280 : 200,
          margin: { t: 30, b: 0, l: 30, r: 0 },
          showlegend: false,
          hovermode: false,
          paper_bgcolor: backgrndcolor,
          plot_bgcolor: backgrndcolor,
          font: {
            color: txtcolor,
          },
          name: 'Starry Night',
        }}
        style={{
          // minWidth: "350px",
          // minHeight: "350px",
          width: '100%',
        }}
        config={{ displayModeBar: false, responsive: true, autosizable: true, staticPlot: true }}
      />
    </>
  );
};

export default DonutChart;
