import React from 'react'
import classes from './ChartAxisLabel.module.css'

export const ChartAxisLabel = props => {
  const {
    chart: {
      height,
      paddingTop,
      paddingBottom,
    },
    yAxisTicksWidth,
    yTicks,
    yScale,
    classPrefix,
    labelName,
    heading,
    tickLabel,
    labelOffset,
  } = props

  const checkForThousands = num => (num > 999 ? `${num / 1000}k` : num)

  const returnY = d => yScale()(d)

  const renderYTicks = () => yTicks.map((yTick, i) => {
    const yCoord = returnY(yTick)
    return (
      <g
        key={i}
        transform={`translate(0, ${yCoord})`}
        className={classes.yTick}
      >
        <text
          dx="0"
          dy=".31em"
          y="0"
          x="0"
          style={{ textAnchor: 'end' }}
        >
          {checkForThousands(yTick)}
        </text>
      </g>
    )
  })

  return (
    <React.Fragment>
      <h2 className={classes[`${classPrefix}${labelName}Heading`]}>
        {heading}
      </h2>
      <svg
        className={classes[`${classPrefix}${labelName}Axis`]}
        height={(height + paddingTop + paddingBottom)}
        width={yAxisTicksWidth}
      >
        <text
          className={classes.label}
          transform={`translate(12, ${labelOffset}) rotate(-90)`}
          x="0"
          y="0"
          dx="0"
          dy=".71em"
        >
          {tickLabel}
        </text>
        <g
          className={classes.yAxisTickGroup}
          transform={`translate(${yAxisTicksWidth - 6}, ${paddingTop})`}
        >
          {yTicks && renderYTicks()}
        </g>
      </svg>
    </React.Fragment>
  )
}

export default ChartAxisLabel
