/* eslint-disable */

export const SEX_OPTION = [
  { value: 'male', label: 'Male' },
  { value: 'female', label: 'Female' },
  // { value: 'other', label: 'Other' }
];

export const CARDIAC_DEVICE = [
  { value: "pacemaker", label: "PACEMAKER" },
  { value: "icd", label: "ICD" },
  { value: "no", label: "NO" },
  { value: "unknown", label: "UNKNOWN" }
];

export const PRIMARY_INSURANCE_TYPE_OPTIONS = [
    { value: 'self_pay', label: 'Self pay' },
    { value: 'medicare', label: 'Medicare' },
    { value: 'commercial_insurance', label: 'Commercial Insurance' }
];

export const SECONDARY_INSURANCE_TYPE_OPTIONS = [
    { value: 'none', label: 'None' },
    { value: 'medicare', label: 'Medicare' },
    { value: 'commercial_insurance', label: 'Commercial Insurance' }
];

export const POLICY_HOLDER_RELATIONSHIP_OPTIONS = [
    { value: 'self', label: 'Self' },
    { value: 'parent', label: 'Parent' },
    { value: 'child', label: 'Child' },
    { value: 'widow', label: 'Widow/Widower' },
];

export const STUDY_SERVICE_TYPE = [
    { value: 'event', label: 'Event' },
    { value: 'holter', label: 'Holter' },
    { value: 'extHolter', label: 'Extended Holter' },
    // { value: 'mct', label: 'MCT' } // Block for now
];

export const DEVICE = [
    { value: 'KM6L', label: 'KM6L' },
    { value: 'kardiaPatch', label: 'Kardia Patch' },
];

export const DEVICE_SERVICE_TYPE_EVENT = [
    { value: 'KM6L', label: 'KM6L' },
];

export const DEVICE_SERVICE_TYPE_HOLTER_EXT_HOLTER = [
    { value: 'kardiaPatch', label: 'Kardia Patch' },
];

export const STUDY_DURATION_DAY_EVENT_MCT = [
    { value: '1', label: '1 days' },
    { value: '2', label: '2 days' },
    { value: '3', label: '3 days' },
    { value: '4', label: '4 days' },
    { value: '5', label: '5 days' },
    { value: '6', label: '6 days' },
    { value: '7', label: '7 days' },
    { value: '8', label: '8 days' },
    { value: '9', label: '9 days' },
    { value: '10', label: '10 days' },
    { value: '11', label: '11 days' },
    { value: '12', label: '12 days' },
    { value: '13', label: '13 days' },
    { value: '14', label: '14 days' },
    { value: '15', label: '15 days' },
    { value: '16', label: '16 days' },
    { value: '17', label: '17 days' },
    { value: '18', label: '18 days' },
    { value: '19', label: '19 days' },
    { value: '20', label: '20 days' },
    { value: '21', label: '21 days' },
    { value: '22', label: '22 days' },
    { value: '23', label: '23 days' },
    { value: '24', label: '24 days' },
    { value: '25', label: '25 days' },
    { value: '26', label: '26 days' },
    { value: '27', label: '27 days' },
    { value: '28', label: '28 days' },
    { value: '29', label: '29 days' },
    { value: '30', label: '30 days' },
];

export const STUDY_DURATION_DAY_HOLTER = [
    { value: '1', label: '1 days' },
    { value: '2', label: '2 days' },
]

export const STUDY_DURATION_DAY_EXT_HOLTER = [
    { value: '1', label: '1 days' },
    { value: '2', label: '2 days' },
    { value: '3', label: '3 days' },
    { value: '4', label: '4 days' },
    { value: '5', label: '5 days' },
    { value: '6', label: '6 days' },
    { value: '7', label: '7 days' },
    { value: '8', label: '8 days' },
    { value: '9', label: '9 days' },
    { value: '10', label: '10 days' },
    { value: '11', label: '11 days' },
    { value: '12', label: '12 days' },
    { value: '13', label: '13 days' },
    { value: '14', label: '14 days' },
]
export const HOOK_UP_TYPE = [
    { value: 'home hookup', label: 'Home  Hook-up (AliveCor Bills Hook-up)' },
    { value: 'clinic hookup', label: 'Clinic  Hook-up (Provider Bills Hook-up)' },
];

export const STATE_LIST = [{
    "name":"Alabama",
    "code":"AL"
 },
 {
    "name":"Alaska",
    "code":"AK"
 },
 {
    "name":"Arizona",
    "code":"AZ"
 },
 {
    "name":"Arkansas",
    "code":"AR"
 },
 {
    "name":"California",
    "code":"CA"
 },
 {
    "name":"Colorado",
    "code":"CO"
 },
 {
    "name":"Connecticut",
    "code":"CT"
 },
 {
    "name":"Delaware",
    "code":"DE"
 },
 {
    "name":"District of Columbia",
    "code":"DC"
 },
 {
    "name":"Florida",
    "code":"FL"
 },
 {
    "name":"Georgia",
    "code":"GA"
 },
 {
    "name":"Hawaii",
    "code":"HI"
 },
 {
    "name":"Idaho",
    "code":"ID"
 },
 {
    "name":"Illinois",
    "code":"IL"
 },
 {
    "name":"Indiana",
    "code":"IN"
 },
 {
    "name":"Iowa",
    "code":"IA"
 },
 {
    "name":"Kansas",
    "code":"KS"
 },
 {
    "name":"Kentucky",
    "code":"KY"
 },
 {
    "name":"Louisiana",
    "code":"LA"
 },
 {
    "name":"Maine",
    "code":"ME"
 },
 {
    "name":"Maryland",
    "code":"MD"
 },
 {
    "name":"Massachusetts",
    "code":"MA"
 },
 {
    "name":"Michigan",
    "code":"MI"
 },
 {
    "name":"Minnesota",
    "code":"MN"
 },
 {
    "name":"Mississippi",
    "code":"MS"
 },
 {
    "name":"Missouri",
    "code":"MO"
 },
 {
    "name":"Montana",
    "code":"MT"
 },
 {
    "name":"Nebraska",
    "code":"NE"
 },
 {
    "name":"Nevada",
    "code":"NV"
 },
 {
    "name":"New Hampshire",
    "code":"NH"
 },
 {
    "name":"New Jersey",
    "code":"NJ"
 },
 {
    "name":"New Mexico",
    "code":"NM"
 },
 {
    "name":"New York",
    "code":"NY"
 },
 {
    "name":"North Carolina",
    "code":"NC"
 },
 {
    "name":"North Dakota",
    "code":"ND"
 },
 {
    "name":"Ohio",
    "code":"OH"
 },
 {
    "name":"Oklahoma",
    "code":"OK"
 },
 {
    "name":"Oregon",
    "code":"OR"
 },
 {
    "name":"Pennsylvania",
    "code":"PA"
 },
 {
    "name":"Rhode Island",
    "code":"RI"
 },
 {
    "name":"South Carolina",
    "code":"SC"
 },
 {
    "name":"South Dakota",
    "code":"SD"
 },
 {
    "name":"Tennessee",
    "code":"TN"
 },
 {
    "name":"Texas",
    "code":"TX"
 },
 {
    "name":"Utah",
    "code":"UT"
 },
 {
    "name":"Vermont",
    "code":"VT"
 },
 {
    "name":"Virginia",
    "code":"VA"
 },
 {
    "name":"Washington",
    "code":"WA"
 },
 {
    "name":"West Virginia",
    "code":"WV"
 },
 {
    "name":"Wisconsin",
    "code":"WI"
 },
 {
    "name":"Wyoming",
    "code":"WY"
 }]