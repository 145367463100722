import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  recording: [],
  preview: [],
  error: null,
};
const getRecordingStart = (state, action) => {
  return updateObject(state, null);
};

const getRecordingSuccess = (state, action) => {
  return updateObject(state, {
    preview: action.payload.result,
  });
};

const getRecordingFail = (state, action) => {
  return updateObject(state, null);
};

const getRecordingSamplesStart = (state, action) => {
  return updateObject(state, null);
};

const getRecordingSamplesSuccess = (state, action) => {
  return updateObject(state, {
    recording: { ...action.payload[0].data, ...action.payload[1].data },
  });
};

const getRecordingSamplesFail = (state, action) => {
  return updateObject(state, { recording: [], error: action.error });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_RECORDING_START:
      return getRecordingStart(state, action);
    case actionTypes.GET_RECORDING_SUCCESS:
      return getRecordingSuccess(state, action);
    case actionTypes.GET_RECORDING_FAIL:
      return getRecordingFail(state, action);
    case actionTypes.GET_RECORDING_SAMPLE_START:
      return getRecordingSamplesStart(state, action);
    case actionTypes.GET_RECORDING_SAMPLE_SUCCESS:
      return getRecordingSamplesSuccess(state, action);
    case actionTypes.GET_RECORDING_SAMPLE_FAIL:
      return getRecordingSamplesFail(state, action);

    default:
      return state;
  }
};

export default reducer;
