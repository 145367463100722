import React from 'react'
import classes from './Marker.module.css'

export const Marker = props => {
  const {
    markerPosition,
    markerRadius,
    height,
    width,
    paddingLeft,
    paddingRight,
    offsetTop,
  } = props

  const renderMarker = () => (
    <rect
      x="0"
      y="0"
      width="14"
      height={height - offsetTop}
      rx={markerRadius}
      ry={markerRadius}
    />
  )

  return (
    <div className={classes.markerContainer}>
      <svg
        className={classes.markerSvg}
        width={width + paddingLeft + paddingRight}
        height={height}
      >
        <g
          className={classes.marker}
          transform={`translate(${markerPosition}, ${offsetTop})`}
        >
          {renderMarker()}
        </g>
      </svg>
    </div>
  )
}

export default Marker
