import 'date-fns';
import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid';
import englishLocale from 'date-fns/locale/en-US';
import spanishLocale from 'date-fns/locale/es';
import frLocale from 'date-fns/locale/fr';
import germanLocale from 'date-fns/locale/de';
import italianLocale from 'date-fns/locale/it';
import { useTranslation } from 'react-i18next';
import { createMuiTheme, ThemeProvider, makeStyles } from '@material-ui/core/styles';

const defaultMaterialTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#2D9F86',
    },
  },
  typography: {
    fontFamily: 'Work Sans',
  },
});

const useStyles = makeStyles(() => ({
  root: {
    '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#2D9F86',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#142A39',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#2D9F86',
    },
    fontFamily: 'Work Sans',
    fontSize: '16px',
    color: '#142A39',
  },
}));

export default function MaterialUIPickers(props) {
  const [selectedDate, setSelectedDate] = React.useState(props.date);
  const { i18n } = useTranslation();
  const classes = useStyles();
  React.useEffect(() => {
    setSelectedDate(props.date);
  }, [props.date]);

  const handleDateChange = (date) => {
    if (date !== null) {
      setSelectedDate(date);
      props.changeHandle(date);
    }
  };
  const localeMap = {
    en: englishLocale,
    es: spanishLocale,
    de: germanLocale,
    it: italianLocale,
    fr: frLocale,
  };

  return (
    // locale={enUS}
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[i18n.language]}>
      <Grid container justify={props.justify ? props.justify : 'space-around'}>
        <ThemeProvider theme={defaultMaterialTheme}>
          <KeyboardDatePicker
            error={props.valid ? true : false}
            autoOk
            fullWidth={true}
            disableToolbar
            variant={props.variant || 'dialog'}
            inputVariant="outlined"
            format="MM/dd/yyyy"
            mask="MM/dd/yyyy"
            margin={props.nomargin ? 'none' : 'normal'}
            label={props.label}
            value={selectedDate}
            onChange={handleDateChange}
            disablePast="true"
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            inputProps={{
              style: {
                padding: props.padding,
                width: props.width,
                fontFamily: 'Work Sans',
              },
            }}
            InputProps={{ readOnly: true, classes: { root: classes.root } }}
          />
        </ThemeProvider>
      </Grid>
    </MuiPickersUtilsProvider>
  );
}
