import React, { Component } from 'react';
import AvailabilityForm from './AvailabilityForm';
import ArrowBackSharpIcon from '@material-ui/icons/ArrowBackSharp';
import ArrowForwardSharpIcon from '@material-ui/icons/ArrowForwardSharp';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import AddIcon from '@material-ui/icons/Add';
import Authorize from '../../Authorize';
import { Select, MenuItem } from '@material-ui/core';
const navigate = {
  PREVIOUS: 'PREV',
  NEXT: 'NEXT',
  TODAY: 'TODAY',
  DATE: 'DATE',
  MONTH: 'month',
  DAY: 'day',
  WEEK: 'week',
};
const start = moment();
const remainder = 15 - (start.minute() % 15);
const date = moment(start).add(remainder, 'minutes').format('YYYY-MM-DDTHH:mm:ss.SSS');
class CustomToolbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      startDate: date,
      endDate: date,
      startTime: date,
      endTime: date,
    };
    this.baseState = this.state;
  }
  componentWillUnmount() {
    this.setState({ isOpen: false });
  }
  modalOpenHandler = () => {
    const start = moment();
    const remainder = 15 - (start.minute() % 15);
    const newDate = moment(start).add(remainder, 'minutes').format('YYYY-MM-DDTHH:mm:ss.SSS');
    this.setState({
      isOpen: true,
      startTime: newDate,
      endTime: newDate,
    });
  };
  modalHandler = () => {
    this.setState({
      isOpen: false,
    });
  };
  navigate = (action) => {
    this.props.onNavigate(action);
  };

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <div className="row py-3">
          <div className="col-12 d-flex flex-row align-items-center justify-content-between">
            <div className="cal_button">
              <Select
                disableUnderline
                labelId="select-calender_view"
                id="select-calender_view"
                value={this.props.view}
                onChange={(event) => this.props.onView(event.target.value)}
                SelectDisplayProps={{
                  style: {
                    border: '1px solid #EFEFEF',
                    borderRadius: 2,
                    width: 90,
                    paddingLeft: 5,
                  },
                }}
              >
                <MenuItem value={navigate.DAY}>{t('Day')}</MenuItem>
                <MenuItem value={navigate.WEEK}>{t('Week')}</MenuItem>
                <MenuItem value={navigate.MONTH}>{t('Month')}</MenuItem>
              </Select>
              {/* <span className="rbc-btn-group m-0">
                <button
                    type="button"
                    className={
                      this.props.view === "day"
                          ? "btn btn_grey py-2 px-4 selected"
                          : "btn btn_grey py-2 px-4 rounded-0"
                    }
                    onClick={this.props.onView.bind(null, navigate.DAY)}
                >
                  {t('Day')}
                </button>
              </span> */}
              {/* <span className="rbc-btn-group m-0">
                <button
                    type="button"
                    className={
                      this.props.view === "week"
                          ? "btn btn_grey py-2 px-4 selected"
                          : "btn btn_grey py-2 px-4 rounded-0"
                    }
                    onClick={this.props.onView.bind(null, navigate.WEEK)}
                >
                  {t('Week')}
                </button>
            </span> */}
              {/* <span className="rbc-btn-group m-0">
                <button
                    type="button"
                    className={
                      this.props.view === "month"
                          ? "btn btn_grey py-2 px-4 selected"
                          : "btn btn_grey py-2 px-4 rounded-0"
                    }
                    onClick={this.props.onView.bind(null, navigate.MONTH)}
                >
                  {t('Month')}
                </button>
            </span> */}
            </div>
            <div className="d-flex flex-row align-items-center">
              <button
                className="btn btn_teal_arrow btn-back"
                onClick={this.navigate.bind(null, navigate.PREVIOUS)}
              >
                <ArrowBackSharpIcon className="prev-icon" />
              </button>
              <h5 className="d-none d-xl-block mb-0">
                {this.props.view === 'day'
                  ? moment(this.props.date).format('MMMM D, YYYY dddd')
                  : this.props.view === 'month'
                  ? moment(this.props.date).format('MMMM, YYYY dddd')
                  : moment(moment(this.props.start)._d).format('MMMM D -') +
                    moment(moment(this.props.end)._d).format('D, YYYY') +
                    moment(this.props.date).format(' dddd')}
              </h5>
              <button
                className="btn btn_teal_arrow btn-next"
                onClick={this.navigate.bind(null, navigate.NEXT)}
              >
                <ArrowForwardSharpIcon className="next-icon" />
              </button>
            </div>
            <div>
              <Authorize>
                <button
                  type="button"
                  className="btn btn_teal py-2 px-4"
                  onClick={this.modalOpenHandler}
                >
                  <AddIcon style={{ top: -2, left: -5, color: 'FFFFFF' }} />
                  {t('ADD AVAILABILITY')}
                </button>
              </Authorize>
            </div>
          </div>
          <h5 className="mx-4 mt-md-3 mt-lg-3 d-block d-xl-none">
            {this.props.view === 'day'
              ? moment(this.props.date).format('MMMM D, YYYY dddd')
              : this.props.view === 'month'
              ? moment(this.props.date).format('MMMM, YYYY dddd')
              : moment(moment(this.props.start)._d).format('MMMM D -') +
                moment(moment(this.props.end)._d).format('D, YYYY dddd') +
                moment(this.props.date).format(' dddd')}
          </h5>
        </div>

        {this.state.isOpen && (
          <AvailabilityForm
            {...this.props}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            startTime={this.state.startTime}
            endTime={this.state.endTime}
            show={this.state.isOpen}
            handleClose={this.modalHandler}
            onSubmit={(e) => this.props.submitHandler(e)}
            recurrsivepattern={'NotRecursive'}
            component="toolbar"
            title={'Add availability'}
          />
        )}
      </React.Fragment>
    );
  }
}

export default withTranslation()(CustomToolbar);
