import { CLEAR_STATE } from '../../constants/app'
import { postResetPassword } from '../../Store/reducers/app'
// ------------------------------------
// Constants
// ------------------------------------
const PREFIX = 'RequestNewPassword.'
export const FORM_WAS_SUBMITTED = `${PREFIX}FORM_WAS_SUBMITTED`
export const CLEAR_INPUT_ERROR_MESSAGE = `${PREFIX}CLEAR_INPUT_ERROR_MESSAGE`
export const PASSWORD_RESET_REQUEST = `${PREFIX}PASSWORD_RESET_REQUEST`
export const PASSWORD_RESET_SUCCESS = `${PREFIX}PASSWORD_RESET_SUCCESS`
export const PASSWORD_RESET_FAILURE = `${PREFIX}PASSWORD_RESET_FAILURE`
export const VALID_EMAIL_INPUT = `${PREFIX}VALID_EMAIL_INPUT`
export const INVALID_EMAIL_INPUT = `${PREFIX}INVALID_EMAIL_INPUT`
export const UPDATE_INPUT = `${PREFIX}UPDATE_INPUT`

// ------------------------------------
// Actions
// ------------------------------------
export function formWasSubmitted() {
  return {
    type: FORM_WAS_SUBMITTED,
  }
}
export function clearInputErrorMessage() {
  return {
    type: CLEAR_INPUT_ERROR_MESSAGE,
  }
}
export function passwordResetRequest() {
  return {
    type: PASSWORD_RESET_REQUEST,
  }
}
export function passwordResetSuccess() {
  return {
    type: PASSWORD_RESET_SUCCESS,
  }
}
export function passwordResetFailure(error) {
  return {
    type: PASSWORD_RESET_FAILURE,
    payload: error,
  }
}
export function validEmailInput() {
  return {
    type: VALID_EMAIL_INPUT,
  }
}
export function invalidEmailInput() {
  return {
    type: INVALID_EMAIL_INPUT,
  }
}
export function updateInput(field) {
  return {
    type: UPDATE_INPUT,
    payload: field,
  }
}

export const postRequestNewPassword = () => (dispatch, getState) => {
  dispatch(formWasSubmitted())
  const {
    email,
  } = getState().requestNewPassword
  const valid = dispatch(minimalEmailValidation())

  if (!valid) {
    return dispatch(invalidEmailInput())
  }

  const emailObj = { email }

  /* Valid email, go talk to the server */
  dispatch(validEmailInput())
  dispatch(passwordResetRequest())
  dispatch(postResetPassword(emailObj))
    .then(success => {
      if (success) {
        dispatch(passwordResetSuccess())
      } else {
        dispatch(passwordResetFailure())
      }
    })
    .catch(err => {
      dispatch(passwordResetFailure(err))
    })
}

export const minimalEmailValidation = () => (dispatch, getState) => {
  const email = getState().requestNewPassword.email || ''

  if (email === '') {
    return false
  }

  /* Email not empty, check if valid email */
  const rX = new RegExp( // Email Regex - local-part@domain - johndoe@gmail.com
    [
      "[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*", // local-part
      '@', // @
      '(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?', // domain
    ].join(''),
    'gi' // global, case insensitive
  )
  return rX.test(email.trim()) // Strip leading and trailing spaces
}

export const handleChange = evt => (dispatch, getState) => {
  const email = evt.target.value
  const { formSubmitted } = getState().requestNewPassword

  if (formSubmitted) {
    dispatch(clearInputErrorMessage())
  }

  dispatch(updateInput(email))
}

export const handleKeyDown = evt => (dispatch, getState) => {
  if (evt.key === 'Enter') {
    return dispatch(postRequestNewPassword())
  }
}

const initialState = {
  fetching: false,
  email: '',
  valid: false,
  formSubmitted: false,
  error: null,
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [FORM_WAS_SUBMITTED]: (state, action) => ({
    ...state, formSubmitted: true,
  }),
  [CLEAR_INPUT_ERROR_MESSAGE]: (state, action) => ({
    ...state, formSubmitted: false, valid: false,
  }),
  [PASSWORD_RESET_REQUEST]: (state, action) => ({
    ...state, fetching: true,
  }),
  [PASSWORD_RESET_SUCCESS]: (state, action) => ({
    ...state, fetching: false,
  }),
  [PASSWORD_RESET_FAILURE]: (state, action) => ({
    ...state,
    fetching: false,
    error: action.payload,
    valid: false,
  }),
  [VALID_EMAIL_INPUT]: (state, action) => ({
    ...state, valid: true,
  }),
  [INVALID_EMAIL_INPUT]: (state, action) => ({
    ...state, valid: false,
  }),
  [UPDATE_INPUT]: (state, action) => ({
    ...state, email: action.payload,
  }),
  [CLEAR_STATE]: (state, action) => ({
    ...initialState,
  }),
}

// ------------------------------------
// Reducer
// ------------------------------------
export default function requestNewPasswordReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
