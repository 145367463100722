import { connect } from 'react-redux';
import MemberList from './MemberList';
import { headCells, memberFilters, mockMemberTableData } from './data/mockData'
import { getMemberDetails, filterAction } from '../../Store/actions';

const mapActionCreators = {
  getMemberDetails,
  filterAction,
};

const mapStateToProps = (state) => ({
  memberList: state.memberList.memberListData,
  count:state.memberList.count,
  headCells: headCells,
  memberFilters: memberFilters,
});

export default connect(mapStateToProps, mapActionCreators)(MemberList);
