import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { Box, Divider, Grid, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {headerInfoIconModalData} from '../data/mockData'

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    '& .MuiTypography-h6':{
        fontWeight: 'bold',
        fontSize: '16px'
    },
    fontFamily: 'Work Sans'
  },
  header: {
    display: 'flex',
    direction: 'row',
    marginBottom: '20px',
  },
  modalBody: {
    display: 'flex',
    direction: 'column',
    justifyContent: 'space-between',
    maxWidth: '400px',
    '& .MuiTypography-body1':{
        fontWeight: 'bold'
    },
    '& .MuiGrid-grid-xs-1':{
        marginRight: '-10px'
    }
  },
  modalClose: {
      position: 'absolute',
      top: '5px',
      right: '5px',
      backgroundColor: 'rgba(20, 42, 57, 0.16)',
  }
}));

export default function InfoIconModal(props) {
  const classes = useStyles();
  return (
    <>
      <Modal
        aria-labelledby="column modal"
        className={classes.modal}
        open={props.open}
        onClose={props.handleModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <Box className={classes.paper}>
            <Grid container className={classes.header} spacing={2}>
                <Grid item xs={10}>
                    <Typography variant={'h6'}>{props.columnName === 'status' ? 'Status Definition' : 'Blood pressure'}</Typography>
                </Grid>
                <Grid item xs={2}>
                    <IconButton onClick={props.handleModalClose} aria-label="Search" className={classes.modalClose}>
                        <CloseIcon />
                    </IconButton>
                </Grid>
            </Grid>
            <Grid container className={classes.modalBody} spacing={2}>
            {props.columnName === 'status' ? (
                headerInfoIconModalData?.status.map((item, index) => (
                    <Grid item xs={12} key={index}>
                        <Typography variant={'body1'}>{`"${item.title}"`}</Typography>
                        <Typography variant={'body2'}>{item.body}</Typography>
                    </Grid>
                ))
            ) : (
                headerInfoIconModalData?.bloodPressure.map((item, index) => {                    
                    let borderColor; 
                 {
                    (() => {
                        if(item.title.toLowerCase() === 'low'){
                            borderColor = '#9DD3DE'
                        }else if(item.title.toLowerCase() === 'normal'){
                            borderColor = '#6BC077'
                        }else if(item.title.toLowerCase() === 'elevated'){
                            borderColor = '#FDC246'                                
                        }else if(item.title.toLowerCase() === 'hypertension stage 1'){
                            borderColor = '#F78C4E'                                
                        }else if(item.title.toLowerCase() === 'hypertension stage 2'){
                            borderColor = '#F25F5F'                                
                        }else if(item.title.toLowerCase() === 'hypertensive crisis'){
                            borderColor = '#CC3D3F'                                
                        }
                    })()
                }
                return (
                    <>
                    <Grid item xs={1} key={index}>
                        <span style={{height: '20px', border: `4px solid ${borderColor}`}}></span>          
                    </Grid>
                    <Grid item xs={5} key={index}>
                        <Typography variant={'body1'}>{item.title}</Typography>
                    </Grid>
                    <Grid item xs={6} key={index} style={{textAlign: 'right'}}>
                        <Typography variant={'body2'}>{item.body}</Typography>
                    </Grid>
                    <Divider />
                    </>
                )})
            )}
            {props.columnName === 'bloodPressure' && (
                <Grid item xs={12}>
                    <Divider />
                    <Typography variant={'body2'} style={{fontSize: '12px'}}>*Based on AHA guidelines</Typography>
                </Grid>
            )}
            </Grid>            
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
