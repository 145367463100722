import React from 'react';
import classes from './CopyrightStatement.module.css';

const CopyrightStatement = (props) => {
  const getYear = () => new Date().getFullYear();

  return (
    <p className={classes.copyrightStatement}>
      {`©${getYear()} AliveCor, Inc. All Rights Reserved. US Patent Nos 8,301,232, 8,509,882 and Patents Pending. AliveCor and Kardia are trademarks of AliveCor, Inc. in the United States and other countries.`}
    </p>
  );
};

export default CopyrightStatement;
