import {ValidatePassword} from '../../Utilities/Utilities'
import { CLEAR_STATE } from '../../constants/app'
import { postResetPasswordToken } from '../../Store/reducers/app'

// ------------------------------------
// Constants
// ------------------------------------
const PREFIX = 'ResetPassword.'
export const DISPLAY_VALIDATION_ERROR = `${PREFIX}DISPLAY_VALIDATION_ERROR`
export const POST_NEW_PASSWORD_SUCCESS = `${PREFIX}POST_NEW_PASSWORD_SUCCESS`
export const POST_NEW_PASSWORD_FAILURE = `${PREFIX}POST_NEW_PASSWORD_FAILURE`
export const REQUEST_POST_NEW_PASSWORD = `${PREFIX}REQUEST_POST_NEW_PASSWORD`
export const STORE_TOKEN = `${PREFIX}STORE_TOKEN`
export const UPDATE_INPUT = `${PREFIX}UPDATE_INPUT`
export const UPDATE_VALIDATION = `${PREFIX}UPDATE_VALIDATION`

export const noMatchError = 'The passwords you entered do not match, please try again'
export const emptyStringError = 'You must enter a password'
export const noValidateError = 'Your password must meet all the criteria, please try again'
export const currentPasswordErr = 'Your current password is incorrect, please try again'

// ------------------------------------
// Actions
// ------------------------------------
export function storeToken(token) {
  return {
    type: STORE_TOKEN,
    payload: token,
  }
}

export function updateInput(field) {
  return {
    type: UPDATE_INPUT,
    payload: field,
  }
}

export function requestPostNewPassword() {
  return {
    type: REQUEST_POST_NEW_PASSWORD,
  }
}

export function postNewPasswordSuccess() {
  return {
    type: POST_NEW_PASSWORD_SUCCESS,
  }
}

export function postNewPasswordFailure(error) {
  return {
    type: POST_NEW_PASSWORD_FAILURE,
    payload: error,
  }
}

export function displayValidationError(msg) {
  return {
    type: DISPLAY_VALIDATION_ERROR,
    payload: msg,
  }
}

export function updateValidation(validationObj) {
  return {
    type: UPDATE_VALIDATION,
    payload: validationObj,
  }
}

export const handleChange = evt => (dispatch, getState) => {
  const field = {
    [evt.target.name]: evt.target.value,
  }

  if (field.password || field.password === '') {
    const result = ValidatePassword(field.password)
    dispatch(updateValidation(result))
  }
  dispatch(updateInput(field))
}

export const handleKeyDown = evt => (dispatch, getState) => {
  if (evt.key === 'Enter') {
    return dispatch(submitNewPassword())
  }
}

export const submitNewPassword = () => (dispatch, getState) => {
  const {
    form: {
      password,
      passwordConfirmation,
    },
    validationTests,
  } = getState().resetPassword
  let passedTests = false
  const testResults = Object.values(validationTests)
  for (let i = 0; i < testResults.length; i += 1) {
    if (!testResults[i]) {
      passedTests = false
      break
    } else {
      passedTests = true
    }
  }

  if (!password) {
    return dispatch(displayValidationError(emptyStringError))
  }

  if (!passedTests) {
    return dispatch(displayValidationError(noValidateError))
  }

  if (password === passwordConfirmation) {
    return dispatch(postNewPassword(password))
  } else {
    return dispatch(displayValidationError(noMatchError))
  }
}

export const postNewPassword = password => (dispatch, getState) => {
  dispatch(requestPostNewPassword())
  const {
    token,
  } = getState().resetPassword
  const passwordObj = { password }
  dispatch(postResetPasswordToken(token, passwordObj))
    .then(res => {
      if (res) {
        dispatch(postNewPasswordSuccess())
      }
    })
    .catch(err => {
      dispatch(postNewPasswordFailure(err.message))
    })
}

export const getToken = (match, location) => (dispatch, getState) => {
  const {
    token,
  } = match.params
  dispatch(storeToken(token))
}

const initialState = {
  error: false,
  errorMsg: '',
  form: {},
  success: false,
  token: '',
  validationTests: {},
  fetching: false,
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [STORE_TOKEN]: (state, action) => ({
    ...state, token: action.payload,
  }),
  [UPDATE_INPUT]: (state, action) => ({
    ...state, form: Object.assign({}, state.form, action.payload),
  }),
  [REQUEST_POST_NEW_PASSWORD]: (state, action) => ({
    ...state, fetching: true,
  }),
  [POST_NEW_PASSWORD_SUCCESS]: (state, action) => ({
    ...state, fetching: false, success: true,
  }),
  [POST_NEW_PASSWORD_FAILURE]: (state, action) => ({
    ...state, fetching: false, error: true, errorMsg: action.payload,
  }),
  [DISPLAY_VALIDATION_ERROR]: (state, action) => ({
    ...state, error: true, errorMsg: action.payload,
  }),
  [UPDATE_VALIDATION]: (state, action) => ({
    ...state, validationTests: action.payload,
  }),
  [CLEAR_STATE]: (state, action) => ({
    ...initialState,
  }),
}


// ------------------------------------
// Reducer
// ------------------------------------
export default function resetPasswordReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
