import React from 'react';
import omit from 'lodash.omit';
import styled, { css } from 'styled-components';

const TAGS = ['h1', 'h2', 'h3', 'h4'];

function getTag(props) {
  if (props.tag) return props.tag;

  for (const t of TAGS) {
    if (props[t]) {
      return t;
    }
  }
  return null;
}

const h1 = (props) =>
  props.h1 &&
  css`
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 20px;
  `;

const h2 = (props) =>
  props.h2 &&
  css`
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 20px;
  `;

const h3 = (props) =>
  props.h3 &&
  css`
    font-size: 20px;
    margin-bottom: 12px;
    font-weight: 500;
  `;

const h4 = (props) =>
  props.h4 &&
  css`
    font-size: 18px;
    margin-bottom: 10px;
    margin-right: 10px;
    font-weight: 500;
  `;

const Heading = styled((props) => {
  const Tag = getTag(props);
  return <Tag {...omit(props, TAGS)} />;
})`
  ${h1} ${h2} ${h3} ${h4};
`;

export default Heading;
