import { connect } from 'react-redux';
import ContentWithNavigationSidebar from './SidebarLayout';

const mapActionCreators = {};

const mapStateToProps = (state) => ({
  sidebarIsCollapsed: state.sidebar.sidebarIsCollapsed,
});

export default connect(mapStateToProps, mapActionCreators)(ContentWithNavigationSidebar);
