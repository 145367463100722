import React from 'react';
import { Column } from 'react-virtualized';

import Table from '../components/Table';
import TableStyles from '../components/Table/Table.module.css';
import TableHeader from '../components/TableHeader';
import TableActions from '../components/TableActions';
import TableActionStyles from '../components/TableActions/TableActions.module.css';
// import { SearchPopover } from '../../Shared/PopOver'
import styles from './TeamInvitesTable.module.css';
import CancelIcon from '../../../Assets/images/icons/cancel.svg';
import EmailIcon from '@material-ui/icons/Email';
import {
  TEAM_INVITES_TABLE_COLUMN_HEADERS,
  TABLE_CHECKBOX_WIDTH,
  TABLE_BULK_ACTIONS,
} from '../../../constants/app';
import Loading from '../../Loading';
import { getSelectedList } from '../../../Utilities/Settings/TableUtilities';
import { SearchPopover } from '../../Popovers';

class TeamInvitesTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: {},
    };
  }

  static getDerivedStateFromProps(props, s) {
    const state = s;

    if (state.selected !== props.selected) {
      state.selected = props.selected;
    }

    return state;
  }

  handleOnSelectionClick = (selected) => {
    const { onSelectionClick } = this.props;

    this.setState({
      selected,
    });

    if (onSelectionClick) {
      onSelectionClick(selected);
    }
  };

  render() {
    const {
      noDataText = 'There are no pending invites.',
      enableLoader,
      showLoader,
      showRowSelection = false,
      data,
      width,
      onRowClick,
      sortBy,
      sortDirection,
      rowHeight = 50,

      filterEmail,
      filterFirstName,
      filterLastName,
      handleOnFilterButtonClick,
      handleOnFilterChange,
      handleOnSortClick,
      handleOnOutsidePopoverClick,
      handleOnBulkAction,
      handleOnBulkActionClearSelection,
      handleOnPopoverKeyUp,
      bulkActionComplete,
      bulkActionIsFetching,
      selectedPopover,

      selectedEmail,
      selectedFirstName,
      selectedLastName,
    } = this.props;
    const { selected } = this.state;

    const isAnySelected = Object.keys(selected).some((key) => selected[key] === true);

    let emailWidth = width;
    let emailPopoverClassname = styles.emailPopover;

    if (showRowSelection) {
      emailWidth -= TABLE_CHECKBOX_WIDTH;
      emailPopoverClassname = styles.emailPopoverWithSelection;
    }

    const numberOfSelection = getSelectedList(selected).length;
    // console.log(data)
    return (
      <div className={styles.teamInvitesTable}>
        <button
          className={TableActionStyles.actionButton}
          style={{
            position: 'absolute',
            top: '-50px',
            right: '10px',
          }}
          disabled={!isAnySelected}
          onClick={() => handleOnBulkAction(TABLE_BULK_ACTIONS.REVOKE_FROM_TEAM)}
        >
          <CancelIcon className={styles.cancelIcon} />
          <span
            style={{
              paddingLeft: '10px',
            }}
          >
            REVOKE
          </span>
        </button>
        <Table
          noDataText={noDataText}
          enableLoader={enableLoader}
          showLoader={showLoader}
          // headerHeight={35}
          showRowSelection={showRowSelection}
          disableRowSelection={bulkActionComplete || bulkActionIsFetching}
          selected={selected}
          rowHeight={rowHeight}
          uniqueKey="id"
          hasTableActions={isAnySelected}
          onSelectionClick={this.handleOnSelectionClick}
          onRowClick={onRowClick}
          data={data}
          sortBy={sortBy}
          sortDirection={sortDirection}
        >
          <Column
            className={TableStyles.column}
            label="Email"
            dataKey={TEAM_INVITES_TABLE_COLUMN_HEADERS.EMAIL}
            width={emailWidth}
            headerRenderer={(headerProps) => {
              const { label } = headerProps;

              return (
                <TableHeader
                  label={label}
                  columnHeader={TEAM_INVITES_TABLE_COLUMN_HEADERS.EMAIL}
                  handleOnSortClick={handleOnSortClick}
                  handleOnFilterButtonClick={handleOnFilterButtonClick}
                  filterActive={filterEmail}
                  sortBy={sortBy}
                  sortDirection={sortDirection}
                  selectedPopover={selectedPopover}
                  Popover={
                    <div className={emailPopoverClassname}>
                      <SearchPopover
                      icon={<EmailIcon style={{color:'black'}}/>}
                        label={label}
                        handleOnPopoverKeyUp={handleOnPopoverKeyUp}
                        handleOnChange={(email) =>
                          handleOnFilterChange(TEAM_INVITES_TABLE_COLUMN_HEADERS.EMAIL, email)
                        }
                        handleOnOutsidePopoverClick={handleOnOutsidePopoverClick}
                        value={selectedEmail}
                        placeholder="Enter Email"
                      />
                    </div>
                  }
                />
              );
            }}
            cellRenderer={({ dataKey, rowData }) => (
              <div className={TableStyles.centeredColumnContent}>
                <p className={TableStyles.primaryText}>{rowData[dataKey]}</p>
              </div>
            )}
          />
          <Column
            className={TableStyles.column}
            label="First Name"
            dataKey={TEAM_INVITES_TABLE_COLUMN_HEADERS.FIRST_NAME}
            width={emailWidth}
            headerRenderer={(headerProps) => {
              const { label } = headerProps;

              return (
                <TableHeader
                  label={label}
                  columnHeader={TEAM_INVITES_TABLE_COLUMN_HEADERS.FIRST_NAME}
                  handleOnSortClick={handleOnSortClick}
                  handleOnFilterButtonClick={handleOnFilterButtonClick}
                  filterActive={filterFirstName}
                  sortBy={sortBy}
                  sortDirection={sortDirection}
                  selectedPopover={selectedPopover}
                  Popover={
                    <div className={emailPopoverClassname}>
                      <SearchPopover
                        label={label}
                        handleOnPopoverKeyUp={handleOnPopoverKeyUp}
                        handleOnChange={(firstName) =>
                          handleOnFilterChange(
                            TEAM_INVITES_TABLE_COLUMN_HEADERS.FIRST_NAME,
                            firstName,
                          )
                        }
                        handleOnOutsidePopoverClick={handleOnOutsidePopoverClick}
                        value={selectedFirstName}
                        placeholder="Enter First Name"
                      />
                    </div>
                  }
                />
              );
            }}
            cellRenderer={({ dataKey, rowData }) => (
              <div className={TableStyles.centeredColumnContent}>
                <p className={TableStyles.primaryText}>{rowData[dataKey]}</p>
              </div>
            )}
          />
          <Column
            className={TableStyles.column}
            label="Last Name"
            dataKey={TEAM_INVITES_TABLE_COLUMN_HEADERS.LAST_NAME}
            width={emailWidth}
            headerRenderer={(headerProps) => {
              const { label } = headerProps;

              return (
                <TableHeader
                  label={label}
                  columnHeader={TEAM_INVITES_TABLE_COLUMN_HEADERS.LAST_NAME}
                  handleOnSortClick={handleOnSortClick}
                  handleOnFilterButtonClick={handleOnFilterButtonClick}
                  filterActive={filterLastName}
                  sortBy={sortBy}
                  sortDirection={sortDirection}
                  selectedPopover={selectedPopover}
                  Popover={
                    <div className={emailPopoverClassname}>
                      <SearchPopover
                        label={label}
                        handleOnPopoverKeyUp={handleOnPopoverKeyUp}
                        handleOnChange={(lastName) =>
                          handleOnFilterChange(
                            TEAM_INVITES_TABLE_COLUMN_HEADERS.LAST_NAME,
                            lastName,
                          )
                        }
                        handleOnOutsidePopoverClick={handleOnOutsidePopoverClick}
                        value={selectedLastName}
                        placeholder="Enter Last Name"
                      />
                    </div>
                  }
                />
              );
            }}
            cellRenderer={({ dataKey, rowData }) => (
              <div className={TableStyles.centeredColumnContent}>
                <p className={TableStyles.primaryText}>{rowData[dataKey]}</p>
              </div>
            )}
          />
        </Table>

        <TableActions
          selected={selected}
          visible={bulkActionComplete}
          clearSelection={handleOnBulkActionClearSelection}
          bulkActionComplete={bulkActionComplete}
          bulkActionIsFetching={bulkActionIsFetching}
          // eventually when there is more than 1 option, this will need to become a system to support which bulk
          // action was taken and show the cooresponding success or failure message
          successMessage={
            numberOfSelection === 1
              ? `${numberOfSelection} invitation has been revoked.`
              : `${numberOfSelection} invitations have been revoked.`
          }
        ></TableActions>
      </div>
    );
  }
}

export default TeamInvitesTable;
