import React from 'react';

import SearchInput from '../../SearchInput';
import Popover from '../components/Popover/Popover';
import styles from './SearchPopover.module.css';

const SearchPopover = (props) => {
  const {
    handleOnChange,
    handleOnOutsidePopoverClick,
    handleOnPopoverKeyUp,
    value,
    placeholder,
    autoFocus = true,
    label,
    icon
  } = props;

  return (
    <Popover className={styles.searchPopover} onOutsidePopoverClick={handleOnOutsidePopoverClick}>
      <p style={{ textAlign: 'left' }}>Filter</p>
      {label && <h3 className={styles.label}>{label}</h3>}
      <SearchInput
        onKeyUp={handleOnPopoverKeyUp}
        autoFocus={autoFocus}
        handleOnChange={handleOnChange}
        value={value}
        placeholder={placeholder}
        icon={icon}
      />
    </Popover>
  );
};

export default SearchPopover;
