import React from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { VALID_AUTH_REGIONS } from '../Login/authConstants';
import { ROUTES, setRegion } from '../../constants/app';

function ResetPasswordEmailLink(props) {
  const {
    location,
    match: { params },
  } = props;
  const qs = queryString.parse(location.search);
  if (qs.region && Object.values(VALID_AUTH_REGIONS).includes(qs.region)) {
    setRegion(qs.region);
  }
  return <Redirect to={`${ROUTES.RESET_PASSWORD}/${params.token}`} />;
}

export default withRouter(ResetPasswordEmailLink);
