import styled, { css } from 'styled-components';

const small = (props) =>
  props.small &&
  css`
    max-width: 580px;
  `;

const large = (props) =>
  props.large &&
  css`
    flex: 1;
  `;

const xlarge = (props) =>
  props.xlarge &&
  css`
    margin: 2.1em;
    flex: 1;
  `;

const titleContainer = (props) =>
  props.titleContainer &&
  css`
    flex: 1;
    height: 40px;
    background: #3da98b;
  `;

const clinicAnalyticsTitle = (props) =>
  props.clinicAnalyticsTitle &&
  css`
    flex: 1;
    height: 70px;
    background: #3da98b;
  `;

const fullscreen = (props) =>
  props.fullscreen &&
  css`
    flex: 1;
  `;

const Container = styled.div`
  flex: 1;

  ${small} ${large} ${xlarge} ${fullscreen} ${titleContainer} ${clinicAnalyticsTitle};
`;

export default Container;
