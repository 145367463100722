/* eslint-disable */
import { connect } from 'react-redux';
import { getFormValues, isPristine } from 'redux-form';
import Settings from './Settings';
import { DATASOURCES, FORMS } from '../../constants/app';
import { getDataSource } from '../../Store/reducers/app';
import {
  getSettingsData,
  handleOnTabClick,
  handleOnInviteSubmit,
  handleOnFilterButtonClick,
  handleOnTeamInvitesFilterChange,
  handleOnTeamInvitesSortClick,
  handleOnTeamInvitesBulkAction,
  updateSelectedTeamInvites,
  handleOnTeamMembersFilterChange,
  handleOnTeamMembersSortClick,
  updateSelectedTeamMembers,
  getTeamInvitesIdsFromDataSource,
  getTeamMembersIdsFromDataSource,
  handleOnTeamMemberRowClick,
  handleOnRefreshClick,
  handleOnSelectionClick,
  handleUpdatePasswordChange,
  getAppointmentNotificationsSettings,
  handleAppointmentNotificationsChange,
  submitUpdatePassword,
  handleInboxSettingsForm,
  handleOnEditTeamMember,
  handleOnEditTeamMemberSubmit,
  getTeamMemberFileData,
  handleUpdateInputChange,
} from './module';

const mapActionCreators = {
  getSettingsData,
  handleOnTabClick,
  handleOnInviteSubmit,
  handleOnFilterButtonClick,
  handleOnTeamInvitesFilterChange,
  handleOnTeamInvitesSortClick,
  handleOnTeamInvitesBulkAction,
  updateSelectedTeamInvites,
  handleOnTeamMembersFilterChange,
  handleOnTeamMembersSortClick,
  updateSelectedTeamMembers,
  handleOnTeamMemberRowClick,
  handleOnRefreshClick,
  handleOnSelectionClick,
  handleUpdatePasswordChange,
  getAppointmentNotificationsSettings,
  handleAppointmentNotificationsChange,
  submitUpdatePassword,
  handleInboxSettingsForm,
  handleOnEditTeamMember,
  handleOnEditTeamMemberSubmit,
  getTeamMemberFileData,
  handleUpdateInputChange,
};

const mapStateToProps = (state) => ({
  notificationFetching: state.app.dataSources?.memberNotificationSettings?.isFetching,
  dataIsFetching: state.settings.dataIsFetching,
  dataLastUpdated: state.settings.dataLastUpdated,
  refreshClicked: state.settings.refreshClicked,
  bulkActionComplete: state.settings.bulkActionComplete,
  bulkActionIsFetching: state.settings.bulkActionIsFetching,
  selectedPageTab: state.settings.selectedPageTab,
  selectedPopover: state.settings.selectedPopover,
  selectedIndex: state.settings.selectedIndex,
  inviteMemberError: state.settings.inviteMemberError,

  teamInvitesIds: getTeamInvitesIdsFromDataSource(state),
  selectedTeamInvites: state.settings.selectedTeamInvites,
  selectedTeamInvitesEmail: state.settings.selectedTeamInvitesEmail,
  selectedTeamInvitesFirstName: state.settings.selectedTeamInvitesFirstName,
  selectedTeamInvitesLastName: state.settings.selectedTeamInvitesLastName,
  teamInvitesSortBy: state.settings.teamInvitesSortBy,
  teamInvitesSortDirection: state.settings.teamInvitesSortDirection,

  teamMembersIds: getTeamMembersIdsFromDataSource(state),
  teamMembersSortBy: state.settings.teamMembersSortBy,
  teamMembersSortDirection: state.settings.teamMembersSortDirection,
  selectedTeamMembers: state.settings.selectedTeamMembers,
  selectedTeamMembersId: state.settings.selectedTeamMembersId,
  selectedTeamMembersEmail: state.settings.selectedTeamMembersEmail,
  selectedTeamMembersCountry: state.settings.selectedTeamMembersCountry,
  selectedTeamMembersRegion: state.settings.selectedTeamMembersRegion,
  selectedTeamMembersAdminPermissionId: state.settings.selectedTeamMembersAdminPermissionId,
  selectedTeamMembersPhysicianPermissionId: state.settings.selectedTeamMembersPhysicianPermissionId,
  selectedTeamMembersCanManageAvailabilityId: state.settings.selectedTeamMembersManagesAvailability,
  pwError: state.settings.pwError,
  pwErrorMsg: state.settings.pwErrorMsg,
  pwIsFetching: state.settings.pwIsFetching,
  validationTests: state.settings.validationTests,
  inputFieldValidation: state.settings.inputFieldValidation,

  appointmentNotifications:state.teamSettings.appointmentNotifications,
  me: getDataSource(state, DATASOURCES.ME),
  teamInvites: getDataSource(state, DATASOURCES.TEAM_INVITES),
  physicians: getDataSource(state, DATASOURCES.PHYSICIANS),
  memberInfo: getDataSource(state, DATASOURCES.MEMBER_INFO),
  teamTriggers: getDataSource(state, DATASOURCES.TEAM_TRIGGERS),
  inboxSettingsFormData: getFormValues(FORMS.INBOX_SETTINGS_FORM)(state),
  inboxSettingsFormPristine: isPristine(FORMS.INBOX_SETTINGS_FORM)(state),
  // memberOnChangeInfo for posting form values on submit
  memberOnChangeInfo: getFormValues(FORMS.UPDATE_PASSWORD_FORM)(state),
  // geeting the invite member form values
  inviteMemberFormValues: getFormValues(FORMS.ADD_NEW_MEMBER_FORM)(state),
});

export default connect(mapStateToProps, mapActionCreators)(Settings);
