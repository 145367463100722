import React from 'react';
import { reduxForm, Field } from 'redux-form';
import styles from './Forms.module.css';
import TextInput from './components/TextInput';
import ReactSelectDropdownInput from './components/ReactSelectDropdownInput';
import CheckboxInput from './components/CheckboxInput';
import Loading from '../Loading';
import { CUSTOM_TRIGGER_OPTION, getPrimaryTriggerOptions } from '../../constants/app';

const InboxSettingsForm = (props) => {
  const {
    handleSubmit,
    triggers,
    invalid,
    pristine,
    submitting,
    isSubmitting,
    inboxSettingsFormData,
  } = props;

  let triggerOptions = [];

  if (triggers && triggers.data) {
    triggerOptions = [...triggerOptions, ...getPrimaryTriggerOptions(triggers.data)];
  }
  // SAME LOGIC AS ENROLLMENT FORM AND PRESCRIBE FORM
  const isCustomSelected =
    inboxSettingsFormData &&
    inboxSettingsFormData.ekgReviewWhichRecordings &&
    inboxSettingsFormData.ekgReviewWhichRecordings.id === CUSTOM_TRIGGER_OPTION.id;
  let isPossibleAFibResultWithHrCheckmarked = false;
  let isHeartRateUnderCheckmarked = false;
  let isHeartRateOverCheckmarked = false;

  if (inboxSettingsFormData) {
    if (inboxSettingsFormData.custom) {
      const { custom } = inboxSettingsFormData;

      isPossibleAFibResultWithHrCheckmarked = custom.possibleAFibResultWithHrCheckmark;
      isHeartRateUnderCheckmarked = custom.heartRateUnderCheckmark;
      isHeartRateOverCheckmarked = custom.heartRateOverCheckmark;
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className={styles.formSection}>
        <div className={styles.formSectionRow}>
          <div className={styles.recordingGroup}>
            <Field
              component={ReactSelectDropdownInput}
              label="Which Recordings to Flag"
              placeholder="Which Recordings to Flag"
              name="ekgReviewWhichRecordings"
              isLoading={triggers && triggers.isFetching}
              isClearable={false}
              backspaceRemovesValue={false}
              options={triggerOptions}
              noOptionsMessage="No recording type found"
              getOptionLabel={(p) => p.label}
              getOptionValue={(p) => p.id}
            />
            {isCustomSelected && (
              <div className={styles.customRules}>
                <h3>Custom Rules</h3>
                <Field
                  component={CheckboxInput}
                  label="Possible AFib result"
                  name="custom.possibleAFibResultCheckmark"
                />
                <Field
                  component={TextInput}
                  type="number"
                  inline
                  disabled={!isPossibleAFibResultWithHrCheckmarked}
                  getLabel={() => (
                    <Field
                      component={CheckboxInput}
                      className={styles.checkboxGroupFixedWidth}
                      label="Possible AFib result with heart rate over"
                      name="custom.possibleAFibResultWithHrCheckmark"
                    />
                  )}
                  name="custom.possibleAFibResultWithHr"
                />
                <Field
                  component={CheckboxInput}
                  label="Sinus Rhythm with Wide QRS"
                  name="custom.sinusRhythmWithWideQRS"
                />
                <Field
                  component={CheckboxInput}
                  label="Sinus Rhythm with SVE"
                  name="custom.sinusRhythmWithSVEs"
                />
                <Field
                  component={CheckboxInput}
                  label="Sinus Rhythm with PVCs"
                  name="custom.sinusRhythmWithPVCs"
                />
                <Field component={CheckboxInput} label="Tachycardia" name="custom.tachycardia" />
                <Field component={CheckboxInput} label="Bradycardia" name="custom.bradycardia" />
                <Field
                  component={CheckboxInput}
                  label="Unclassified result"
                  name="custom.unclassifiedResultCheckmark"
                />
                <Field
                  component={TextInput}
                  type="number"
                  inline
                  disabled={!isHeartRateUnderCheckmarked}
                  getLabel={() => (
                    <Field
                      component={CheckboxInput}
                      className={styles.checkboxGroupFixedWidth}
                      label="Heart rate under"
                      name="custom.heartRateUnderCheckmark"
                    />
                  )}
                  name="custom.heartRateUnder"
                />
                <Field
                  component={TextInput}
                  type="number"
                  inline
                  disabled={!isHeartRateOverCheckmarked}
                  getLabel={() => (
                    <Field
                      component={CheckboxInput}
                      className={styles.checkboxGroupFixedWidth}
                      label="Heart rate over"
                      name="custom.heartRateOverCheckmark"
                    />
                  )}
                  name="custom.heartRateOver"
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className={styles.submitWrapper}>
        <div className={styles.buttonWrapper}>
          {submitting || isSubmitting ? (
            <button disabled className={styles.submitLoading}>
              <Loading />
            </button>
          ) : (
            <button type="submit" disabled={invalid || pristine} className={styles.submit}>
              Save
            </button>
          )}
        </div>
      </div>
    </form>
  );
};

export default reduxForm({
  form: 'inboxSettingsForm',
  enableReinitialize: true,
})(InboxSettingsForm);
