/* eslint-disable */
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import AlertTitle from '@material-ui/lab/AlertTitle';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    '& .MuiCollapse-wrapperInner': {
      background: '#FFF8D3',
    },
    '& .MuiAlertTitle-root': {
      maxWidth: 180,
      color: '#142A39',
      fontWeight: 'bold',
      fontFamily: 'Work Sans',
      fontSize: '14px',
      lineHeight: '24px',
      display: 'flex',
      alignItems: 'center',
      letterSpacing: '-0.2px',
      wordWrap: 'break-word',
    },
  },
}));

export default function TitleAlert(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [title, settitle] = React.useState('');
  useEffect(() => {
    setOpen(true);
    settitle(props.title);
  }, []);

  return (
    <div className={classes.root}>
      <Collapse in={open} timeout="auto">
        <Alert
          color="inherit"
          icon={false}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="medium"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="default" />
            </IconButton>
          }
        >
          <AlertTitle>{title}</AlertTitle>
        </Alert>
      </Collapse>
    </div>
  );
}
